import React, { useEffect, useRef, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import "./custom-styles.css";
import { Buttons, DateBox, DateContainer, DateRange, Filter } from "./styles";
import { de } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { GetIcon } from "../../../icons";
import { Button } from "../elements";
function DateRangeSelector(props) {
  const { i18n } = useTranslation();
  const { datefilterType } = props;
  const currentLanguage = i18n.language;
  const [filterType, setFilterType] = useState(null);
  const locale = {
    ...de,
    options: {
      ...de.options,
      weekdays: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
      relativeTime: {
        ...de.options.relativeTime,
        future: "in %s",
        past: "vor %s",
        s: "wenigen Sekunden",
        m: "einer Minute",
        mm: "%d Minuten",
        h: "einer Stunde",
        hh: "%d Stunden",
        d: "einem Tag",
        dd: "%d Tagen",
        M: "einem Monat",
        MM: "%d Monaten",
        y: "einem Jahr",
        yy: "%d Jahren",
      },
    },
  };
  const staticRanges = [
    {
      label: "Letzten Monat", // "Last Month"
      range: () => ({
        startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
        endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
      }),
      isSelected: (ranges) => {},
    },
    {
      label: "Diesen Monat", // "This Month"
      range: () => ({
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
      }),
      isSelected: (ranges) => {},
    },
    {
      label: "Letzte 30 Tage", // "Last 30 Days"
      range: () => {
        const endDate = new Date();
        const startDate = new Date(new Date().setDate(endDate.getDate() - 30));
        return {
          startDate,
          endDate,
        };
      },
      isSelected: (ranges) => {},
    },
    {
      label: "Letzte 90 Tage", // "Last 90 Days"
      range: () => {
        const endDate = new Date();
        const startDate = new Date(new Date().setDate(endDate.getDate() - 90));
        return {
          startDate,
          endDate,
        };
      },
      isSelected: (ranges) => {},
    },
    {
      label: "Letzte 180 Tage", // "Last 180 Days"
      range: () => {
        const endDate = new Date();
        const startDate = new Date(new Date().setDate(endDate.getDate() - 180));
        return {
          startDate,
          endDate,
        };
      },
      isSelected: (ranges) => {},
    },
    {
      label: "Letzte 365 Tage", // "Last 365 Days"
      range: () => {
        const endDate = new Date();
        const startDate = new Date(new Date().setDate(endDate.getDate() - 365));
        return {
          startDate,
          endDate,
        };
      },
      isSelected: (ranges) => {},
    },
  ];

  const setDates = (ranges) => {
    const dates = ranges[0];

    // Check if both startDate and endDate are present and startDate is not later than endDate
    if (dates?.startDate && dates?.endDate && dates.startDate < dates.endDate) {
      setDateRange(ranges);
      props.onChange(dates, filterType);
      setDateRangeStatus(false);
    } else {
      // If the condition is not met, only update the local state without closing the picker
      setDateRange(ranges);
    }
  };
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  // const handleTimeFrameSelection = (days) => {
  //   const endDate = new Date();
  //   const startDate = new Date(new Date().setDate(endDate.getDate() - days));
  //   setDateRange([{ startDate, endDate, key: "selection" }]);
  //   props.onChange({ startDate, endDate });
  // };

  const formatStartDate = dateRange[0]?.startDate ? moment(dateRange[0].startDate).format("MMM D") : currentLanguage === "de" ? "Von" : "From";
  const formatEndDate = dateRange[0]?.endDate ? moment(dateRange[0].endDate).format("MMM D") : currentLanguage === "de" ? "Bis" : "To";

  const [dateRangeStatus, setDateRangeStatus] = useState(false);
  const showPricker = (value) => {
    setDateRangeStatus(value);
  };

  const selectRef = useRef(null);

  useEffect(() => {
    function handleClick(event) {
      if (!selectRef.current.contains(event.target)) {
        setDateRangeStatus(false);
      }
    }
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
  return (
    <DateBox ref={selectRef}>
      <span>{currentLanguage === "de" ? "Nach Datum filtern" : "Filter By Date"}</span>
      <Filter
        theme={props.themeColors}
        onClick={() => {
          showPricker(!dateRangeStatus);
        }}
      >
        {formatStartDate} - {formatEndDate}
        <GetIcon icon={"down"}></GetIcon>
      </Filter>
      <DateContainer className={dateRangeStatus}>
        <Buttons>
          {datefilterType?.map((item) => (
            <Button
              ClickEvent={() => {
                setFilterType(item.id);
                props.onFilterChange(item, "dateFilterType", "select");
              }}
              isDisabled={filterType === item.id}
              align="datefilter"
              value={item.value}
            ></Button>
          ))}
        </Buttons>
        <DateRange
          {...(currentLanguage === "de" ? { locale } : {})} // Conditionally include locale
          className={dateRangeStatus}
          ranges={dateRange}
          staticRanges={staticRanges}
          showSelectionPreview={true} // Show the selection preview
          showMonthAndYearPickers={true} // Show the month and year pickers
          showDateDisplay={false} // Hide the date display in the top bar
          months={1}
          onChange={(ranges) => {
            setDates([ranges.selection]);
          }}
          minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
          maxDate={new Date(new Date().setMonth(new Date().getMonth() + 4))} // Set maxDate to 4 months from now
        />
      </DateContainer>
    </DateBox>
  );
}
export default DateRangeSelector;
