import React from "react";
import { Button } from "../../../../core/elements";

const ActionButtons = ({ editMode, setEditMode, currentState, handleAction, setShowChat, setShowDetails, t }) => {
  const handleEditMode = () => {
    setEditMode(!editMode);
    if (!editMode) {
      setShowChat(true);
      setShowDetails(false);
    }
  };

  const renderButtons = () => {
    const buttons = [];

    // Render action buttons
    currentState.stage.innerAction?.actions?.forEach((action, index) => {
      const type = action.showType ?? "edit";
      if ((editMode && type === "edit") || type === "always") {
        buttons.push(
          <Button
            key={`${action.label}-${action.id || index}`} // Use action.id if available
            ClickEvent={() => handleAction(action)}
            icon={action.icon}
            value={t(action.label)}
          />
        );
      }
    });

    // Render cancel button in edit mode
    if (editMode) {
      buttons.push(
        <Button
          key="cancel-button" // Unique key for the cancel button
          style={{ background: "#DDDDDD", color: "#6A6A6A" }}
          ClickEvent={handleEditMode}
          value={t("cancel")}
        />
      );
    }

    // Render edit button when not in edit mode and editable is true
    if (!editMode && currentState.stage.innerAction?.editable) {
      buttons.push(
        <Button
          key="edit-button" // Unique key for the edit button
          icon={currentState.stage.innerAction.editIcon}
          style={{ background: "#F9E375", color: "#094B96" }}
          ClickEvent={handleEditMode}
          value={t(currentState.stage.innerAction.editLabel)}
        />
      );
    }

    return buttons;
  };

  return renderButtons();
};

export default ActionButtons;
