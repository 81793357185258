import React, { useState, useEffect, useCallback, useRef } from "react";
import parse from "html-react-parser";
import { FragDiv } from "./styles";

const DynamicTemplate = ({ item, onChange, fieldData, onChildChange, childData, addNewChild, setLoaderBox, fullScreen }) => {
  const [fields, setFields] = useState(JSON.parse(item.fields?.length > 0 ? item.fields : "[]"));
  const [processedHtml, setProcessedHtml] = useState(parse(item.htmlTemplate));
  const cursorPositionRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  // Update fields when item changes
  useEffect(() => {
    setFields(JSON.parse(item.fields?.length > 0 ? item.fields : "[]"));
  }, [item]);

  // Function to save cursor position
  const saveCursorPosition = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(range.startContainer);
      preCaretRange.setEnd(range.startContainer, range.startOffset);
      cursorPositionRef.current = {
        node: range.startContainer,
        offset: range.startOffset,
      };
    }
  };

  // Function to restore cursor position
  const restoreCursorPosition = useCallback(() => {
    if (!cursorPositionRef.current || !isFocused) {
      return;
    }

    const selection = window.getSelection();
    if (!selection) {
      return;
    }

    const range = document.createRange();
    range.setStart(cursorPositionRef.current.node, cursorPositionRef.current.offset);
    range.collapse(true);

    selection.removeAllRanges();
    selection.addRange(range);
  }, [cursorPositionRef, isFocused]);
  
  const replacePlaceholders = useCallback(
    (template, data) => {
      const htmlWithInputs = template.replace(/\{(.*?)\}/g, (match, key) => {
        const field = data?.find((field) => field.name === key);
        if (field) {
          if (field.type === "list") {
            const childTemplate = field;
            if (childTemplate) {
              let childrenHtml = "";
              const dataset = field.dataset ?? [];
              const childDataItem = childData?.[key] ?? [];
              const childFields = childTemplate.fields;
              for (let i = 0; i < childDataItem.length; i++) {
                childrenHtml += childTemplate.htmlTemplate.replace(/\{(.*?)\}/g, (childMatch, childKey) => {
                  const childField = childFields?.find((field) => field.name === childKey);
                  if (childField) {
                    if (childField.type === "parent") {
                      const parentField = data?.find((field) => field.name === childField.name);
                      if (parentField) {
                        return fieldData?.[childField.name] ? fieldData[childField.name] : parentField.type === "image" ? parentField.sampleImage : parentField.sampleText;
                      }
                      return childMatch;
                    } else {
                      if (childField) {
                        return childField.type === "image" ? childDataItem?.[i]?.[childKey] || dataset?.[i]?.[childKey] || childField.sampleImage : `<span contenteditable="true" style="cursor:text" suppressContentEditableWarning={true} key="render${childKey}-${i}" data-type="child" data-main-key="${key}" data-index="${i}" data-key="${childKey}">${childDataItem?.[i]?.[childKey] || dataset?.[i]?.[childKey] || childField.sampleText}</span>`;
                      }
                      return childMatch;
                    }
                  } else {
                    return "(N/A)" + childMatch;
                  }
                });
                // childrenHtml+="<button>Delete</button>"
              }
              childrenHtml += fullScreen ? "" : `<button className="add-new" data-button-type="add-child" data-button-key="${key}">Add New</button>`;
              return childrenHtml;
            }
          }
          if (field) {
            return field.type === "image" ? (fieldData?.[key] ? fieldData[key] : field.sampleImage) : `<span contenteditable="true" style="cursor: text" suppressContentEditableWarning={true} key="${key}" data-key="${key}">${fieldData?.[key] ? fieldData[key] : field.sampleText}</span>`;
          }
        }
        return `{${key}}`;
      });
      return parse(htmlWithInputs);
    },
    [fieldData, childData, fullScreen]
  );

  useEffect(() => {
    const newHtml = replacePlaceholders(item.htmlTemplate, fields);
    setProcessedHtml(newHtml);
    // Restore cursor position after HTML update
    setTimeout(restoreCursorPosition, 0);
  }, [item.htmlTemplate, fields, fieldData, childData, replacePlaceholders, restoreCursorPosition]);

  const handleInputChange = (e) => {
    // Save cursor position before state update
    saveCursorPosition();

    const type = e.target.getAttribute("data-type");
    const key = e.target.getAttribute("data-key");
    const value = e.target.innerText;

    if (type === "child") {
      const index = e.target.getAttribute("data-index");
      const parent = e.target.getAttribute("data-main-key");
      onChildChange(index, parent, key, value);
    } else {
      onChange(key, value);
    }
  };

  const handleButtonClick = (e) => {
    const buttonType = e.target.getAttribute("data-button-type");
    const buttonKey = e.target.getAttribute("data-button-key");
    if (buttonType === "add-child") {
      addNewChild(buttonKey);
    }
  };

  return (
    <FragDiv onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)}>
      {React.Children.map(processedHtml, (child, index) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            onInput: handleInputChange,
            onClick: handleButtonClick,
            key: "edit-" + index,
          });
        }
        return child;
      })}
    </FragDiv>
  );
};

export default DynamicTemplate;
