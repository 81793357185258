import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "../utils/cropImage";
import { getData, postData } from "../../../backend/api";
import { Button, IconButton, Select } from "../elements";
import "./styles.css";
import { GetIcon } from "../../../icons";
import { useTranslation } from "react-i18next";
import Loader from "../loader";
import { AspectRatioContainer, Buttons, CropperContainer, DropzoneContainer, ImageGrid, ImageUploaderContainer, ImageWrapper, Ratio } from "./styles";

const ImageUploader = ({ onSelect, defaultRatio = { width: 4, height: 3 }, references = {} }) => {
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [loaderBox, setLoaderBox] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [compatibleImages, setCompatibleImages] = useState([]);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [selected, setSelected] = useState(null);
  const [openChild, setOpenChild] = useState(null);
  const { t } = useTranslation();
  const [ratioOptions] = useState([
    { id: 5 / 2, value: "Logo", width: 5, height: 2 },
    { id: 3 / 1, value: "3:1 (Wide Logo)", width: 3, height: 1 },
    { id: 4 / 1, value: "4:1 (Extra Wide Logo)", width: 4, height: 1 },
    { id: 5 / 1, value: "5:1 (Super Wide Logo)", width: 5, height: 1 },
    { id: 6 / 1, value: "6:1 (Ultra Wide Logo)", width: 6, height: 1 },
    { id: 1, value: "1:1 (Quadratisch)", width: 1, height: 1 },
    { id: 4 / 3, value: "4:3 (Standardformat)", width: 4, height: 3 },
    { id: 16 / 9, value: "16:9 (Breitbild)", width: 16, height: 9 },
    { id: 5 / 2, value: "5:2 (Landschaftsformat)", width: 5, height: 2 },
    { id: 3 / 2, value: "3:2 (Leicht breiter als hoch)", width: 3, height: 2 },
    { id: 2 / 1, value: "2:1 (Panoramaformat)", width: 2, height: 1 },
    { id: 9 / 16, value: "9:16 (Hochformat)", width: 9, height: 16 },
    { id: 2 / 3, value: "2:3 (Portrait)", width: 2, height: 3 },
    { id: 4 / 5, value: "4:5 (Portrait)", width: 4, height: 5 },
    { id: 3 / 1, value: "3:1 (Wide Banner)", width: 3, height: 1 },
    { id: 5 / 4, value: "5:4 (Leicht quadratisch)", width: 5, height: 4 },
    { id: 21 / 9, value: "21:9 (Ultra-Widescreen)", width: 21, height: 9 },
    { id: 16 / 10, value: "16:10 (Widescreen)", width: 16, height: 10 },
  ]);
  const [aspectRatioOptions, setSspectRatioOptions] = useState([]);
  const [aspect, setAspect] = useState(ratioOptions.find((item) => item.height === defaultRatio.height && item.width === defaultRatio.width));
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setImage(URL.createObjectURL(file));
    setSelected(null);
    // Get the image size
    // const img = new Image();
    // img.src = URL.createObjectURL(file);
    // img.onload = () => {
    //   setImageSize({ width: img.width, height: img.height });
    // };
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: { "image/*": [] } });

  const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  useEffect(() => {
    const ratios = ratioOptions.map((ratio) => {
      if (ratio.height === defaultRatio.height && ratio.width === defaultRatio.width) {
        return { ...ratio, value: ratio.value + " (Compatible)" };
      } else {
        return ratio;
      }
    });
    setSspectRatioOptions(ratios);
    const item = ratioOptions.find((item) => item.height === defaultRatio.height && item.width === defaultRatio.width);
    setAspect(item);
  }, [defaultRatio, ratioOptions]);
  const handleCrop = async () => {
    if (image && croppedAreaPixels) {
      setLoaderBox(true);
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);
      await uploadImage(croppedImage);
      setImage(null);
      fetchImages(); // Fetch updated images after upload
    }
  };

  // Fetch images from the server
  // Fetch images from the server
  const fetchImages = useCallback(async () => {
    try {
      setLoaderBox(true);
      const response = await getData({ aspectWidth: defaultRatio.width, aspectHeight: defaultRatio.height, ...references }, "media-gallery");
      setUploadedImages(response.data.response);
      setCompatibleImages(response.data.compatible);
      setLoaderBox(false);
    } catch (error) {
      setLoaderBox(false);
      console.error(error);
    }
  }, [setLoaderBox, setUploadedImages, defaultRatio, setCompatibleImages, references]);

  // Upload image to the server
  const uploadImage = async (File) => {
    console.log(aspect);
    if (aspect) {
      const response = await postData(selected ? { File: [File], ID: selected, aspect: aspect.id, aspectWidth: aspect.width, aspectHeight: aspect.height, ...references } : { File: [File], aspect: aspect.id, aspectWidth: aspect.width, aspectHeight: aspect.height, ...references }, "media-gallery");
      if (response.status === 200) {
        return null;
      }
      return null;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (!isImageLoaded) {
      setIsImageLoaded(true);
      fetchImages(); // Fetch images when the component mounts
    }
  }, [isImageLoaded, fetchImages]);

  const startCropping = async (image) => {
    const img = await downloadImage(image.URL);
    setImage(img);
  };
  const downloadImage = async (croppedImage) => {
    try {
      const response = await fetch(croppedImage);
      const clonedResponse = response.clone();
      const blob = await clonedResponse.blob();
      const url = URL.createObjectURL(blob);
      return url;
    } catch (error) {
      console.error("Error downloading image:", error);
      return null;
    }
  };

  const checkAspectRatio = useCallback(
    (width, height) => {
      if (width === 0 || height === 0) return "Invalid dimensions";

      const ratio = width / height;
      const tolerance = 0.01;

      for (let option of aspectRatioOptions) {
        if (Math.abs(ratio - option.id) < tolerance) {
          return option.value;
        }
      }

      return `${width}:${height} (Custom)`;
    },
    [aspectRatioOptions]
  );
  return (
    <ImageUploaderContainer>
      {!image && (
        <DropzoneContainer {...getRootProps()} isDragActive={isDragActive}>
          <input {...getInputProps()} />
          <p>Drag & drop an image here, or click to select one</p>
        </DropzoneContainer>
      )}
      {image && typeof image === "string" && (
        <CropperContainer>
          <Cropper image={image} crop={crop} zoom={zoom} aspect={aspect.id} onCropChange={setCrop} onZoomChange={setZoom} showGrid={true} onCropComplete={onCropComplete} />
          <div className="controls">
            <input
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e) => {
                setZoom(e.target.value);
              }}
              className="zoom-range"
            />
          </div>
        </CropperContainer>
      )}
      {image && typeof image === "string" && (
        <AspectRatioContainer>
          {(defaultRatio.anySize ?? false) && <Select align="filter header " showLabel={false} label={"Aspect Ratio:"} apiType="JSON" selectApi={aspectRatioOptions} value={aspect.id} onSelect={(item) => setAspect(item)}></Select>}
          <Button icon={"upload"} ClickEvent={handleCrop} value="Crop and Upload" />
          <Button icon={"close"} align="red" ClickEvent={() => setImage(null)} value="Cancel" />
        </AspectRatioContainer>
      )}
      <ImageGrid>
        <ImageWrapper className="center">
          <GetIcon icon={"layer"}></GetIcon>
          {t("Compatible")} ({compatibleImages.length})
        </ImageWrapper>
        {compatibleImages?.map((image) => {
          return (
            <ImageWrapper key={"compatible-" + image.ID} className={openChild === image.ID ? "open" : ""}>
              <img src={image.URL} alt="Uploaded" />
              <Ratio>{checkAspectRatio(image.Width, image.Height)}</Ratio>
              <Buttons>
                <IconButton label={"Select"} align="small" icon={"checked"} ClickEvent={() => onSelect(`${image.URL}?id=${image.ID}`)}></IconButton>
              </Buttons>
            </ImageWrapper>
          );
        })}
      </ImageGrid>
      <ImageGrid>
        <ImageWrapper className="center">
          <GetIcon icon={"layer"}></GetIcon>
          <span>
            {t("All Images")} ({uploadedImages.length})
          </span>
        </ImageWrapper>
        {uploadedImages.map((image) => (
          <React.Fragment key={image.ID}>
            <ImageWrapper className={openChild === image.ID ? "open" : ""}>
              <img src={image.URL} alt="Uploaded" />
              <Ratio>{checkAspectRatio(image.aspectWidth, image.aspectHeight)}</Ratio>
              <Buttons>
                {defaultRatio.height === image.aspectHeight && defaultRatio.width === image.aspectWidth ? (
                  <IconButton align="small" label={"Select"} icon={"checked"} ClickEvent={() => onSelect(`${image.URL}?id=${image.ID}`)}></IconButton>
                ) : (
                  <IconButton
                    label={"Crop to Use"}
                    align="small"
                    icon={"crop"}
                    ClickEvent={() => {
                      setSelected(image.ID);
                      startCropping(image);
                    }}
                  ></IconButton>
                )}

                {image.children?.length > 0 ? (
                  <IconButton
                    align="small"
                    icon={openChild === image.ID ? "close" : "layer"}
                    ClickEvent={() => {
                      setOpenChild((prev) => (prev === image.ID ? null : image.ID));
                    }}
                  ></IconButton>
                ) : (
                  <IconButton align="small delete" icon={"delete"} ClickEvent={() => {}}></IconButton>
                )}
              </Buttons>
            </ImageWrapper>
            {openChild === image.ID &&
              image.children?.map((imageChild) => {
                return (
                  <ImageWrapper key={imageChild.ID} className={openChild === image.ID ? "open" : ""}>
                    <img src={imageChild.URL} alt="Uploaded" />
                    <Ratio>{checkAspectRatio(imageChild.aspectWidth, imageChild.aspectHeight)}</Ratio>
                    <Buttons>
                      {defaultRatio.height === imageChild.aspectHeight && defaultRatio.width === imageChild.aspectWidth && <IconButton align="small" icon={"checked"} ClickEvent={() => onSelect(`${imageChild.URL}?id=${imageChild.ID}`)}></IconButton>}
                      <IconButton
                        align="small"
                        icon={"crop"}
                        ClickEvent={() => {
                          setSelected(imageChild.ID);
                          startCropping(imageChild);
                        }}
                      ></IconButton>
                      <IconButton align="small delete" icon={"delete"} ClickEvent={() => {}}></IconButton>
                    </Buttons>
                  </ImageWrapper>
                );
              })}
          </React.Fragment>
        ))}
      </ImageGrid>
      {loaderBox && <Loader></Loader>}
    </ImageUploaderContainer>
  );
};

export default ImageUploader;
