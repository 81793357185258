import React from "react";
import { GridButton, GridWrapper, NumberInput, ToggleSwitch } from "./styles";

export const INPUT_TYPES = {
  SINGLE_SELECT: "SINGLE_SELECT",
  MULTI_SELECT: "MULTI_SELECT",
  BOOLEAN: "BOOLEAN",
  NUMBER: "NUMBER",
  HEADING: "HEADING",
};

export const UnifiedInput = ({ type, options, selected, onChange, renderLabel, min, max, value, className }) => {
  const renderSingleSelect = () => (
    <GridWrapper className={className}>
      {options?.map((option) => (
        <GridButton key={option} span={option} active={selected === option} onClick={() => onChange(option)}>
          {renderLabel?.(option) || option}
        </GridButton>
      ))}
    </GridWrapper>
  );

  const renderMultiSelect = () => (
    <GridWrapper className={className}>
      {options?.map((option) => (
        <GridButton
          key={option}
          span={option}
          active={selected?.includes(option)}
          onClick={() => {
            const updatedSelection = selected?.includes(option) ? selected.filter((item) => item !== option) : [...(selected || []), option];
            onChange(updatedSelection);
          }}
        >
          {renderLabel?.(option) || option}
        </GridButton>
      ))}
    </GridWrapper>
  );

  const renderBoolean = () => (
    <GridWrapper className={className}>
      <ToggleSwitch>
        <input type="checkbox" checked={value} onChange={(e) => onChange(e.target.checked)} />
        <span />
      </ToggleSwitch>
    </GridWrapper>
  );

  const renderNumber = () => (
    <GridWrapper className={className}>
      <NumberInput type="number" min={min} max={max} value={value} onChange={(e) => onChange(parseInt(e.target.value))} />
    </GridWrapper>
  );

  switch (type) {
    case INPUT_TYPES.SINGLE_SELECT:
      return renderSingleSelect();
    case INPUT_TYPES.MULTI_SELECT:
      return renderMultiSelect();
    case INPUT_TYPES.BOOLEAN:
      return renderBoolean();
    case INPUT_TYPES.NUMBER:
      return renderNumber();
    default:
      return null;
  }
};
