import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AutoForm from "../../../core/autoform/AutoForm";
import { postData } from "../../../../backend/api";
import Loader from "../../../core/loader";
import { BtnForgotPassword, SuccessMessageContainer } from "../styles";
import { useSelector } from "react-redux";
import { GetIcon } from "../../../../icons";

const Registration = ({ setMessage, setMainFormType }) => {
  const themeColors = useSelector((state) => state.themeColors);
  const { t } = useTranslation();
  const [formType, setFormType] = React.useState("registration");
  const [email, setEmail] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [otp, setOtp] = React.useState(null);
  const [registered, setRegistered] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const formConfigs = useMemo(
    () => ({
      registration: [
        {
          type: "text",
          placeholder: "email",
          name: "email",
          validation: "email",
          default: "",
          add: true,
          label: "email",
          minimum: 5,
          maximum: 40,
          required: true,
        },
        ...(showSuggestion
          ? [
              {
                type: "html",
                content: (
                  <BtnForgotPassword>
                    <GetIcon icon={"info"}></GetIcon>
                    <span>{t("CompanyAlreadyRegistered")}</span>
                  </BtnForgotPassword>
                ),
                name: "info",
                add: true,
              },
              {
                type: "html",
                content: (
                  <BtnForgotPassword className="hover" onClick={() => setMainFormType("signup")}>
                    <GetIcon icon={"open"}></GetIcon>
                    <span>{t("CompanyNotRegistered")}</span>
                  </BtnForgotPassword>
                ),
                name: "info",
                add: true,
              },
            ]
          : []),
      ],
      otp: [
        {
          type: "text",
          placeholder: "Enter OTP",
          name: "otp",
          validation: "otp",
          default: "",
          add: true,
          label: "OTP",
          minimum: 6,
          maximum: 6,
          required: true,
        },
        {
          type: "info",
          content: t("otpSentMessage", { email: email }),
          name: "info",
          add: true,
        },
      ],
      account: [
        {
          type: "text",
          placeholder: "firstName",
          name: "firstName",
          validation: "",
          customClass: "half",
          default: user?.firstName ?? "",
          add: true,
          label: "firstName",
          minimum: 2,
          half: true,
          maximum: 40,
          required: true,
        },
        {
          type: "text",
          placeholder: "lastName",
          name: "lastName",
          validation: "",
          default: user?.lastName ?? "",
          customClass: "half",
          add: true,
          half: true,
          label: "lastName",
          minimum: 2,
          maximum: 40,
          required: true,
        },
        {
          type: "password",
          placeholder: "password",
          name: "password",
          validation: "password",
          default: "",
          add: true,
          label: "password",
          minimum: 8,
          maximum: 40,
          required: true,
        },
      ],
    }),
    [email, user, showSuggestion, setMainFormType, t]
  );

  const submitChange = useCallback(
    async (post) => {
      setLoader(true);
      const endpoints = {
        registration: "user/register",
        otp: "user/verify-otp",
        account: "user/set-account",
      };
      try {
        const response = await postData(
          {
            ...(formType === "registration" ? { email: post.email } : email && { email: email }),
            ...(formType === "otp" || formType === "account" ? { otp: post.otp || otp } : {}),
            ...post,
          },
          endpoints[formType]
        );
        if (response.status === 200) {
          setFormType((prevType) => {
            switch (prevType) {
              case "registration":
                setEmail(post.email);
                setLoader(false);
                return "otp";
              case "otp":
                setOtp(post.otp);
                setLoader(false);
                setUser(response.data);
                return "account";
              case "account":
                setMessage({
                  type: 1,
                  content: "Your account has been setup successfully!",
                  proceed: "Okay",
                  icon: "success",
                });
                setLoader(false);
                setRegistered(true);
                return "account";
              default:
                setLoader(false);
                return prevType;
            }
          });
        } else {
          setLoader(false);
          setShowSuggestion(true);
          setMessage({
            type: 1,
            content: t(response.customMessage),
            proceed: "Okay",
            icon: "error",
          });
        }
      } catch (error) {
        setLoader(false);
        setShowSuggestion(true);
        setMessage({
          type: 1,
          content: t(error),
          proceed: "Okay",
          icon: "error",
        });
        console.error("Error during form submission:", error);
        // Handle error (e.g., show error message to user)
      }
    },
    [formType, email, setLoader, otp, setMessage, t]
  );
  const formInput = formConfigs[formType];
  const getButtonText = () => {
    switch (formType) {
      case "registration":
        return t("Register");
      case "otp":
        return t("Verify OTP");
      case "account":
        return t("Create Account");
      default:
        return t("Submit");
    }
  };
  return (
    <>
      {registered ? (
        <SuccessMessageContainer theme={themeColors}>
          <div className="success-icon">✓</div>
          <h2>{t("registrationSuccessful")}</h2>
          <p>{t("thankYouForRegistering")}</p>
        </SuccessMessageContainer>
      ) : (
        <AutoForm useCaptcha={false} key={`type-${formType === "registration" && showSuggestion ? `${formType}-1` : formType}`} useCheckbox={false} customClass="embed" description="" formValues={{}} formMode="double" formType="post" header={t("regHead")} formInput={formInput} submitHandler={submitChange} button={t(getButtonText())} isOpenHandler={() => {}} css="plain embed" isOpen={true} plainForm={true} />
      )}
      {loader && <Loader />}
    </>
  );
};

export default Registration;
