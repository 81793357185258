import { DateRangePicker } from "react-date-range";
import styled from "styled-components";
export const DateBox = styled.div`
  position: relative;
  width: max-content;
  display: flex;
  align-items: center;
  gap: 10px;
  > span {
    font-size: 12px;
    color: rgb(35, 34, 39);
  }
`;
export const DateContainer = styled.div`
  position: absolute;
  z-index: 1002;
  display: none !important;
  top: 50px;
  border-radius: 10px;
  background: #ffffff;
  &.true {
    display: flex !important;
    flex-direction: column;
  }
`;

export const Buttons = styled.div`
  display: flex;
  border-bottom: 1px solid #eff2f7;
  padding: 10px 20px;
  gap: 10px;
`;

export const DateRange = styled(DateRangePicker)`
  /* position: absolute; */
  z-index: 1002;
  display: none !important;
  top: 50px;
  border-radius: 10px;
  &.true {
    display: flex !important;
  }
`;
export const Filter = styled.button`
  background: transparent;
  padding: 0 0.5em;
  font-size: 1em;
  margin-right: 0em;
  outline: none;
  border: 0px solid #ddd;
  border: 0px solid #ddd;
  cursor: pointer;
  height: 40px;
  margin-right: 0px;
  padding: 12px;
  background: ${(props) => props.theme.background};
  color: ${(props) => props.theme.theme};
  border-radius: 4px;
  margin-top: 0px;
  cursor: pointer;
  font-weight: 700;
  font-size: 13.5px;
  width: max-content;
  min-width: 150px;
  box-shadow: rgb(221, 234, 250) 5px 5px 20px;
  text-align: left;
  padding-left: 1em;
  padding-right: 1.5em;
  justify-content: space-between;
  display: flex;
  &:hover {
    color: ${(props) => props.theme.bgPrimary};
  }
`;
