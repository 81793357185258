import { useEffect, useState } from "react";
import { MultiTabs } from "../../../core/elements";
import { projectSettings } from "../../brand/project";
import { Container } from "../../../core/layout/styels";
import { RowContainer } from "../../../styles/containers/styles";
import { useDispatch, useSelector } from "react-redux";
import { JobAdd } from "./JobAd";
import { TextAdd } from "./textAd";
import Layout from "../../../core/layout";
import ListTable from "../../../core/list/list";
import { postData } from "../../../../backend/api";
import moment from "moment";
import { fetchCounts } from "../../../../store/actions/counts";
const ApprovalTool = (props) => {
  const { setMessage } = props;
  const themeColors = useSelector((state) => state.themeColors);
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = `Task - ${projectSettings.title}`;
  }, []);
  const [attributes] = useState([
    {
      type: "select",
      placeholder: "status",
      name: "CurrentPhase",
      showItem: "status",
      collection: "",
      validation: "",
      value: "alltasks",
      default: "alltasks",
      label: "status",
      apiType: "JSON",
      showLabel: true,
      selectApi: [
        { id: "all", value: "All" },
        { id: "alltasks", value: "All Tasks" },
        { id: "a46f78a5-057f-bd43-9333-7f00e91faf82", value: "Create ad" },
        { id: "c77989be-0a31-ef4c-88b9-317879c713e0", value: "Proofread ad" },
        { id: "6d510853-ef78-403c-83de-f87e4a50e02c", value: "Modifying ad" },
        { id: "dcdfda1b-8ad4-a743-a3eb-25becd588212", value: "Client Approval" },
        { id: "a52e4e72-95e7-6a43-8aef-ce11a9963263", value: "Ad Approved" },
        { id: "a0f74602-f5ab-ed47-aefb-5eca62f05775", value: "Ad Booked" },
        { id: "b776360a-3d44-484b-905c-a63b86bb0d8c", value: "Online Check" },
        { id: "f8dd7e57-a94a-934b-902e-5c66b1026452", value: "Online" },
      ],
      filter: true,
      filterPlacement: "header",
      filterAlign: "left",
      required: false,
      view: false,
      add: false,
      update: false,
      tag: false,
    },
    {
      type: "text",
      placeholder: "IssueNumber",
      name: "IssueNumber",
      showItem: "IssueNumber",
      collection: "SalesDocument",
      validation: "",
      default: "",
      label: "IssueNumber",
      required: true,
      view: true,
      add: true,
      update: false,
      tag: true,
    },
    {
      type: "text",
      placeholder: "JobTitle",
      name: "Titel",
      showItem: "Titel",
      validation: "",
      default: "",
      label: "JobTitle",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
      filter: true,
      searchKey: "Titel",
    },
    {
      type: "text",
      placeholder: "Phase",
      name: "Phase",
      showItem: "ShortTitle",
      validation: "",
      collection: "StellenanzeigenPhase",
      default: "",
      label: "Phase",
      required: true,
      view: true,
      add: true,
      update: false,
      tag: true,
      searchKey: "Phase",
    },
    {
      type: "text",
      placeholder: "JobID",
      name: "JobID",
      showItem: "JobID",
      collection: "",
      validation: "",
      default: "",
      label: "JobID",
      required: true,
      view: true,
      add: true,
      update: false,
      tag: false,
    },
    {
      type: "text",
      placeholder: "StellenanzeigeURL1",
      name: "StellenanzeigeURL1",
      showItem: "StellenanzeigeURL1",
      customClass: "full",
      collection: "",
      validation: "",
      default: "",
      label: "StellenanzeigeURL1",
      required: false,
      view: true,
      add: true,
      update: true,
      tag: false,
      wrap: true,
    },
    {
      type: "text",
      placeholder: "StellenanzeigeURL2",
      name: "StellenanzeigeURL2",
      showItem: "StellenanzeigeURL2",
      collection: "",
      validation: "",
      customClass: "full",
      default: "",
      label: "StellenanzeigeURL2",
      required: false,
      view: true,
      add: true,
      update: true,
      tag: false,
      wrap: true,
    },

    {
      type: "text",
      placeholder: "Place",
      name: "Place",
      showItem: "Place",
      collection: "",
      validation: "",
      default: "",
      label: "Place",
      required: false,
      view: true,
      add: true,
      update: false,
      tag: false,
    },
    {
      type: "combination",
      placeholder: "jobPortal",
      name: "JobPortal",
      showItem: "title",
      collection: "Portalschaltungens",
      combination: true,
      validation: "",
      default: "",
      label: "JobPortal",
      required: true,
      view: true,
      add: true,
      update: false,
      tag: false,
    },
    {
      type: "date",
      placeholder: "WiedervorlageDatum",
      name: "WiedervorlageDatum",
      showItem: "WiedervorlageDatum",
      collection: "",
      validation: "",
      default: "",
      label: "WiedervorlageDatum",
      filter: true,
      filterPlacement: "header",
      filterAlign: "right",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
      sort: true,
      defaultSort: "desc",
    },
    {
      type: "date",
      placeholder: "ReleaseDate",
      name: "ReleaseDate",
      showItem: "ReleaseDate",
      collection: "",
      validation: "",
      default: "",
      label: "ReleaseDate",
      filter: false,
      filterPlacement: "header",
      filterAlign: "right",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
      sort: false,
      defaultSort: "desc",
    },
    {
      type: "select",
      placeholder: "DateFilterType",
      name: "DateFilterType",
      showItem: "DateFilterType",
      collection: "",
      validation: "",
      value: "lessorequal",
      default: "lessorequal",
      label: "DateFilterType",
      apiType: "JSON",
      showLabel: true,
      selectApi: [
        { id: "lessorequal", value: "Smaller/same" },
        { id: "greaterorequal", value: "Bigger/same" },
        { id: "notequal", value: "Unequal" },
        { id: "modified", value: "Modified Today" },
        { id: "today", value: "Due/Today" },
      ],
      filter: true,
      filterPlacement: "header",
      filterAlign: "right",
      required: false,
      view: false,
      add: false,
      update: false,
      tag: false,
    },
    {
      type: "date",
      placeholder: "online",
      name: "StartDate",
      showItem: "StartDate",
      collection: "",
      validation: "",
      default: "",
      minDate: moment().subtract(4, "months").toDate(),
      label: "online",
      required: false,
      view: true,
      add: false,
      update: true,
      tag: true,
      sort: true,
      defaultSort: "desc",
    },
    {
      type: "date",
      placeholder: "offline",
      name: "EndDate",
      showItem: "EndDate",
      collection: "",
      validation: "",
      showStatus: "isActive",
      default: "",
      minDate: moment().subtract(4, "months").toDate(),
      label: "offline",
      required: false,
      view: true,
      add: false,
      update: true,
      tag: true,
      sort: true,
      defaultSort: "desc",
    },
    {
      type: "number",
      placeholder: "Clicks",
      name: "Clicks",
      validation: "",
      default: "",
      collection: "Portalschaltungens",
      showItem: "Clicks",
      label: "Clicks",
      required: true,
      view: true,
      add: false,
      update: false,
      tag: true,
    },
    {
      type: "text",
      placeholder: "Company",
      name: "Company",
      showItem: "Company",
      collection: "Company",
      validation: "",
      default: "",
      label: "Company",
      required: true,
      view: true,
      add: false,
      update: false,
      tag: true,
      filter: true,
      wrap: true,
      searchKey: "Company.Company",
    },
    {
      type: "text",
      placeholder: "FullName",
      name: "contactName",
      showItem: "Salutation",
      collection: "Contact",
      validation: "",
      default: "",
      label: "FullName",
      required: false,
      view: true,
      add: false,
      update: false,
      tag: false,
      filter: false,
      searchKey: "FullName",
      wrap: true,
    },
    {
      type: "text",
      placeholder: "Email",
      name: "Email",
      showItem: "Email",
      collection: "Contact",
      validation: "",
      default: "",
      label: "Email",
      required: false,
      view: true,
      add: false,
      update: false,
      tag: false,
      filter: false,
    },
    {
      type: "datetime",
      placeholder: "CreatedOn",
      name: "CreatedOn",
      showItem: "CreatedOn",
      collection: "",
      validation: "",
      default: "",
      label: "CreatedOn",
      required: false,
      view: true,
      add: false,
      update: false,
      tag: true,
      sort: true,
      defaultSort: "desc",
    },
  ]);

  const [Portalschaltungen] = useState([
    {
      type: "text",
      placeholder: "title",
      name: "title",
      showItem: "title",
      collection: "",
      validation: "",
      default: "",
      label: "title",
      required: true,
      view: true,
      add: true,
      update: false,
      tag: true,
      wrap: true,
    },
    {
      type: "text",
      placeholder: "AgenturRefNo",
      name: "AgenturRefNo",
      showItem: "AgenturRefNo",
      collection: "",
      validation: "",
      default: "",
      label: "AgenturRefNo",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
      filter: false,
      searchKey: "AgenturRefNo",
    },
    {
      type: "text",
      placeholder: "BuchungsUID",
      name: "BuchungsUID",
      showItem: "BuchungsUID",
      collection: "Product",
      validation: "",
      default: "",
      label: "BuchungsUID",
      required: true,
      view: true,
      add: false,
      update: false,
      tag: true,
    },

    {
      type: "text",
      placeholder: "Locations",
      name: "Locations",
      showItem: "Locations",
      collection: "",
      validation: "",
      default: "",
      label: "Locations",
      required: false,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "text",
      placeholder: "PostalCode",
      name: "PostalCode",
      showItem: "PostalCode",
      collection: "",
      validation: "",
      default: "",
      label: "PostalCode",
      required: false,
      view: true,
      add: true,
      update: true,
      tag: false,
    },
    {
      type: "text",
      placeholder: "SearchOverview",
      name: "SearchOverview",
      showItem: "SearchOverview",
      collection: "",
      validation: "",
      default: "",
      label: "SearchOverview",
      required: false,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "text",
      placeholder: "Product",
      name: "Product",
      showItem: "ProductName",
      collection: "Product",
      validation: "",
      default: "",
      label: "Product",
      required: true,
      view: true,
      add: false,
      update: false,
      tag: false,
    },
    {
      type: "date",
      placeholder: "online",
      name: "DateOnline",
      showItem: "DateOnline",
      collection: "",
      validation: "",
      default: "",
      label: "online",
      required: false,
      minDate: moment().subtract(4, "months").toDate(),
      view: true,
      add: false,
      update: true,
      tag: true,
      sort: true,
      defaultSort: "desc",
    },
    {
      type: "date",
      placeholder: "offline",
      name: "DateOffline",
      showItem: "DateOffline",
      collection: "",
      validation: "",
      default: "",
      label: "offline",
      required: false,
      view: true,
      minDate: moment().subtract(4, "months").toDate(),
      add: false,
      update: true,
      tag: true,
      sort: true,
      defaultSort: "desc",
    },
    {
      type: "text",
      placeholder: "PortalschaltungURL",
      name: "PortalschaltungURL",
      validation: "",
      default: "",
      collection: "",
      showItem: "PortalschaltungURL",
      label: "PortalschaltungURL",
      required: false,
      view: true,
      add: false,
      update: true,
      tag: true,
      wrap: true,
    },
    {
      type: "number",
      placeholder: "Clicks",
      name: "Clicks",
      validation: "",
      default: "",
      collection: "",
      showItem: "Clicks",
      label: "Clicks",
      required: true,
      view: true,
      add: false,
      update: true,
      tag: true,
    },
  ]);
  const [Activities] = useState([
    {
      type: "textarea",
      placeholder: "Comment",
      name: "Comment",
      showItem: "Comment",
      collection: "",
      validation: "",
      default: "",
      label: "Comment",
      required: true,
      view: true,
      add: true,
      update: false,
      tag: true,
    },
    {
      type: "file",
      placeholder: "Document",
      name: "File",
      showItem: "file",
      collection: "",
      validation: "",
      default: "",
      label: "Document",
      required: true,
      view: true,
      add: true,
      update: false,
      tag: true,
    },
  ]);
  const [PhaseHistory] = useState([
    {
      type: "text",
      placeholder: "updatedBy",
      name: "updatedBy",
      showItem: "FirstName",
      collection: "MovedBy",
      validation: "",
      default: "",
      label: "updatedBy",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "text",
      placeholder: "phaseFrom",
      name: "phaseFrom",
      showItem: "Title",
      collection: "MovedFrom",
      validation: "",
      default: "",
      label: "phaseFrom",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "text",
      placeholder: "phaseTo",
      name: "phaseTo",
      showItem: "Title",
      collection: "MovedTo",
      validation: "",
      default: "",
      label: "phaseTo",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "date",
      placeholder: "CreatedOn",
      name: "CreatedOn",
      showItem: "CreatedOn",
      collection: "",
      validation: "",
      default: "",
      label: "CreatedOn",
      required: false,
      view: true,
      add: false,
      update: false,
      tag: true,
      sort: true,
      defaultSort: "desc",
    },
  ]);
  const [Todo] = useState([
    {
      type: "select",
      placeholder: "TodoStatus",
      name: "TodoStatus",
      showItem: "value",
      collection: "TodoStatus",
      validation: "",
      label: "TodoStatus",
      apiType: "API",
      selectApi: "todo/status",
      showLabel: true,
      filter: false,
      filterPlacement: "header",
      filterAlign: "left",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "select",
      placeholder: "Category",
      name: "TodoCategory",
      showItem: "Title",
      collection: "Category",
      validation: "",
      label: "Category",
      showLabel: true,
      apiType: "API",
      selectApi: "todo/categories",
      filter: false,
      filterPlacement: "header",
      filterAlign: "left",
      required: true,
      view: false,
      add: true,
      update: true,
      tag: false,
    },
    {
      type: "text",
      placeholder: "Title",
      name: "Title",
      showItem: "Title",
      collection: "",
      validation: "",
      default: "",
      label: "Title",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "textarea",
      placeholder: "Description",
      name: "Description",
      showItem: "Description",
      collection: "",
      validation: "",
      default: "",
      label: "Description",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "date",
      placeholder: "DueDate",
      name: "DueDate",
      showItem: "DueDate",
      collection: "",
      validation: "",
      default: "",
      label: "DueDate",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
      sort: true,
      defaultSort: "desc",
    },
    {
      type: "datetime",
      placeholder: "ModifiedOn",
      name: "ModifiedOn",
      showItem: "ModifiedOn",
      collection: "",
      validation: "",
      default: "",
      label: "ModifiedOn",
      required: false,
      view: true,
      add: false,
      update: false,
      tag: true,
      sort: true,
      defaultSort: "desc",
    },
    {
      type: "text",
      placeholder: "ModifiedBy",
      name: "ModifiedBy",
      showItem: "FirstName",
      collection: "ModifiedByUser",
      validation: "",
      default: "",
      label: "ModifiedBy",
      required: false,
      view: true,
      add: false,
      update: false,
      tag: true,
      sort: true,
      defaultSort: "desc",
    },
  ]);
  const [actions] = useState([
    {
      element: "open-button",
      type: "callback",
      callback: async (item, data, refreshUpdate, slNo) => {
        // Handle request change/correction action

        setMessage({
          type: 2,
          content: `Are you sure to move phase from ${data.StellenanzeigenPhase.Title}?`,
          proceed: "Move",
          onProceed: async () => {
            props.setLoaderBox(true);
            const response = await postData({ ID: data.ID, SortID: data.StellenanzeigenPhase.SortID, StellenanzeigenPhase: data.StellenanzeigenPhase.ID }, "stellenanzeigen/move");
            if (response.status === 200) {
              setMessage({
                type: 1,
                content: response.data.message,
                icon: "success",
              });

              props.setLoaderBox(false);
              const newPhase = response.data.newPhaseData;
              refreshUpdate(true, slNo, { CurrentPhase: newPhase.ID, StellenanzeigenPhase: { ID: newPhase.ID, ShortTitle: newPhase.ShortTitle, SortID: newPhase.SortID, Title: newPhase.Title } });
              dispatch(fetchCounts(true));
            } else {
              setMessage({
                type: 1,
                content: response.customMessage,
                icon: "error",
              });
              props.setLoaderBox(false);
            }
            props.setLoaderBox(false);
            return false;
          },
        });
      },
      icon: "change",
      title: "Move Phase",
    },
    {
      element: "button",
      type: "subList",
      id: "Portalschaltungen",
      title: "Portalschaltungen",
      icon: "Portalschaltungen",
      attributes: Portalschaltungen,
      params: {
        api: `portalschaltungen`,
        parentReference: "StellenanzeigenID",
        viewMode: "table",
        itemTitle: {
          name: "title",
          type: "text",
          collection: "",
        },
        shortName: "Portalschaltungen",
        addPrivilege: false,
        delPrivilege: false,
        updatePrivilege: true,
        customClass: "medium",
        formMode: "single",
      },
    },
    {
      element: "button",
      type: "subList",
      id: "Activities",
      title: "Activities",
      icon: "upload",
      attributes: Activities,
      params: {
        api: `activities`,
        parentReference: "StellenanzeigenID",
        viewMode: "files",
        itemTitle: {
          name: "title",
          type: "text",
          collection: "",
        },
        fileSource: "File",
        shortName: "Activities",
        addPrivilege: true,
        delPrivilege: false,
        updatePrivilege: true,
        customClass: "medium",
        formMode: "single",
      },
    },
    {
      element: "button",
      type: "subList",
      id: "PhaseHistory",
      title: "PhaseHistory",
      icon: "change",
      attributes: PhaseHistory,
      params: {
        api: `stellenanzeigen/phase-history`,
        parentReference: "StellenanzeigenID",
        viewMode: "table",
        itemTitle: {
          name: "title",
          type: "text",
          collection: "",
        },
        shortName: "PhaseHistory",
        addPrivilege: false,
        delPrivilege: false,
        updatePrivilege: false,
        customClass: "medium",
        formMode: "single",
      },
    },
    {
      element: "button",
      type: "subList",
      id: "Todo",
      title: "Todo",
      icon: "change",
      attributes: Todo,
      params: {
        api: `stellenanzeigen/todo`,
        parentReference: "StellenanzeigenID",
        viewMode: "table",
        itemTitle: {
          name: "title",
          type: "text",
          collection: "",
        },
        shortName: "Todo",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
        formMode: "single",
      },
    },
  ]);
  return props.userType === 3 ? (
    <Container className="noshadow">
      <RowContainer theme={themeColors} className={"page-layout"}>
        <MultiTabs
          tabs={[
            { name: "0", title: "jobAd", element: <JobAdd {...props} isAdmin={true} setLoaderBox={props.setLoaderBox} setMessage={setMessage} /> },
            { name: "1", title: "socialMediaDesigns" },
            { name: "2", title: "textCreationDesigns", element: <TextAdd isAdmin={true} /> },
          ]}
        ></MultiTabs>
      </RowContainer>
    </Container>
  ) : (
    <Container className="noshadow">
      {/* <RowContainer className={"data-layout"}>
    {data && data.map((item, index) => <div key={index}>{item.Titel}</div>)}
  </RowContainer> */}
      {/* Render a ListTable component */}
      <ListTable
        viewMode="table"
        // Actions to be displayed in the ListTable
        actions={actions}
        popupMode={"large"}
        // API endpoint for fetching menu data
        api={`dashboard/dataentry`}
        // Property name for the title of each menu item
        itemTitle={{ name: "Titel", type: "text", collection: "" }}
        // Short name or label for the menu
        shortName={`Job Ads`}
        // Privilege flag indicating whether the user can add menu items
        // formMode={`single`}
        formMode={`double`}
        searchPrivilege={true}
        //
        enableViewItem={true}
        {...props}
        updatePrivilege={true}
        // Additional attributes related to the menu
        attributes={attributes}
      ></ListTable>
    </Container>
  );
};
export default Layout(ApprovalTool);
