import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getData } from "../../../../../backend/api";

const Container = styled.div`
  padding: 0 30px 30px;
`;

const Grid = styled.div`
  display: flex;
  gap: 16px;
  overflow-x: auto;
`;

const Card = styled.div`
  border: 0px solid #ddd;
  border-radius: 8px;
  padding: 0px;
  width: 254.09px;
  img {
    height: 371.54px;
    border-radius: 12px;
    object-fit: cover;
  }
  h3 {
    color: black;
    margin: 0px;
    text-align: center;
    margin-bottom: 10px;
    font-size: 15px;
  }
`;

const Button = styled.button`
  background: #094b96;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  width: 255.38px;
  height: 46.26px;
  padding: 12px 30px;
  border-radius: 8px;
  &:hover {
    background: #1d4ed8;
  }
`;

const TemplateList = ({ onTemplateSelect }) => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    getData({}, "template/select")
      .then((response) => {
        console.log(response);
        setTemplates(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  if (loading) return <Container>Loading...</Container>;
  if (error) return <Container>Error: {error}</Container>;

  return (
    <Container>
      <Grid>
        {templates.map((template) => (
          <Card key={template.id}>
            <img src={template.image ?? "https://www.personalturm.de/Vorschaulink/Griesemann/2024/banner_lq_assistenz_der_bereichsleitung.jpg"} alt={template.title} style={{ width: "254.09px", height: "371px", objectFit: "cover", marginBottom: "5px" }} />
            <h3>{template.value}</h3>
            <Button onClick={() => onTemplateSelect(template)}>Choose</Button>
          </Card>
        ))}
      </Grid>
    </Container>
  );
};

export default TemplateList;
