import React from "react";
import { FooterBox, FooterContaner, ButtonContanter, FooterMainBox } from "./styels";
import { Button } from "../../elements";
import { help } from "../../../project/brand";

const Footer = ({ t }) => {
  return (
    <FooterContaner>
      <FooterMainBox>
        <img alt="help" src={help}></img>
        <FooterBox>
          <div>{t("getNeed")}</div>
          <ButtonContanter>
            <Button icon={"support"} type="small" value="support"></Button>
            <Button icon={"sales"} type="small" value="sales"></Button>
          </ButtonContanter>
        </FooterBox>
      </FooterMainBox>
    </FooterContaner>
  );
};

export default Footer;
