import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { MainContent, Sidebar, RightContent, PreviewArea, PlaceholderText, Controls, ElementBox, Actions, MainCntrols, Sections, Section, SideHead, SectionContaier, SectionContaieritem, MainContainer, FormSections, GridWrapper, GridButton, Floating, Accordian, AccordianTitle, AccordianContent } from "./styles";
import { getData, postData } from "../../../backend/api";
import { IconButton, IconButtonTransparent, TabButtons, Button } from "../elements";
import { GetIcon } from "../../../icons";
import DynamicTemplate from "./dynamic";
import DynamicSidebar from "./dynamicSidebar";
import { useTranslation } from "react-i18next";
import { Container, Left, Right, Title } from "../layout/header/styels";
import { DownloadHtml } from "../functions/downloadHtml";
import StyledColorBoxTheme, { getColorVars } from "./themeColors";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
const JobAdBuilder = ({ onClose, item, setMessage, setLoaderBox, adBuildTemplate, portal }) => {
  const [openedStyles, setOpenedStyles] = useState([false, false]);
  const [colors, setColors] = useState(null);
  const [cssVariables, setCssVariables] = useState("");
  const [screenSize, setScreenSize] = useState("Desktop");
  const [fullScreen, setFullScreen] = useState(false);
  const [jobadChanged, setJobadChanged] = useState(false);
  const [currentSidebar, setCurrentSidebar] = useState("elements");
  const [selectionType, setSelectionType] = useState("Elements");
  const [currentSidebarData, setCurrentSidebarData] = useState({});
  const [jobAd, setJobAd] = useState([]);
  const { t } = useTranslation();
  const [sections, setSections] = useState([]);
  const [styles, setStyles] = useState("");
  const [history, setHistory] = useState([]); // History of job ads
  const [currentIndex, setCurrentIndex] = useState(0); // Current position in history
  const [templates, setTemplates] = useState(null);
  const updateJobAdWork = (jobad) => {
    setJobAd(jobad);
    setJobadChanged(true);

    // Create a new history entry
    const newHistory = [...history.slice(0, currentIndex + 1), jobad];
    if (newHistory.length > 10) {
      newHistory.shift(); // Remove the oldest backup if more than 10
    }

    setHistory(newHistory);
    setCurrentIndex(newHistory.length - 1); // Set to the latest index
  };

  const undo = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setJobAd(history[currentIndex - 1]);
      setCurrentSidebar("elements");
    }
  };

  const redo = () => {
    if (currentIndex < history.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setJobAd(history[currentIndex + 1]);
      setCurrentSidebar("elements");
    }
  };
  const [jobAdLoaded, setJobAdLoaded] = useState("");
  useEffect(() => {
    const getJobAd = async () => {
      const response = await getData({ reference: item.ID, template: adBuildTemplate.id }, "jobad");

      try {
        const draft = JSON.parse(response.data.response.draft);
        const colors = response.data.response.colors ?? response.data.template.colors;
        setColors(colors);
        setCssVariables(getColorVars(colors));
        setJobAd(draft);
        setHistory([draft]);
      } catch (err) {
        console.log(err);
      }
      setStyles(response.data.template.draftStyles);
    };
    if (!jobAdLoaded) {
      getJobAd();
    }
    setJobAdLoaded(true);
  }, [item.ID, jobAdLoaded, adBuildTemplate.id]);

  const getJobAd = async () => {
    const response = await getData({ reference: item.ID }, "jobad");
    try {
      const draft = JSON.parse(response.data.response.draft);
      setJobAd(draft);
      setHistory([draft]);
      setCurrentSidebar("elements");
    } catch (err) {
      console.log(err);
    }
  };
  const updateJobAd = async () => {
    const response = await postData({ reference: item.ID, draft: JSON.stringify(jobAd) }, "jobad");
    if (response.status === 200) {
      setCurrentSidebar("elements");
      setMessage({
        type: 1,
        content: response.data.message,
        icon: "success",
      });
    }
  };
  const updateColors = async (colors) => {
    const response = await postData({ reference: item.ID, colors }, "jobad");
    if (response.status === 200) {
      setMessage({
        type: 1,
        content: response.data.message,
        icon: "success",
      });
    }
  };
  const publishJobad = async () => {
    const response = await postData({ reference: item.ID, draft: JSON.stringify(jobAd) }, "jobad/publish");
    if (response.status === 200) {
      setCurrentSidebar("elements");
      setMessage({
        type: 1,
        content: response.data.message,
        icon: "success",
      });
    }
  };

  // const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    // Create a <style> element
    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = cssVariables + styles;
    // Append the <style> element to the <head>
    style.id = "mobile-styles";

    const modifyMediQueryToCanvas = () => {
      // Replace all instances of "media" with "canvas" in the style innerHTML
      style.innerHTML = style.innerHTML.replace(/@media/g, "@container");
    };

    modifyMediQueryToCanvas();
    document.head.appendChild(style);
    // Cleanup function to remove the style when the component unmounts
    return () => {
      document.head.removeChild(style);
    };
  }, [styles, cssVariables]);

  const renderElementData = (element, elementIndex) => {
    const section = sections.find((item) => item.key === element.elementType);
    const item = section.templates?.find((item) => item._id === element.template);
    if (item) {
      return (
        <DynamicTemplate
          fullScreen={fullScreen}
          key={"dynamic" + elementIndex}
          setLoaderBox={setLoaderBox}
          item={item}
          fieldData={element.data}
          childData={element.childData}
          onChange={(key, value) => {
            const data = { ...element.data, [key]: value };
            handleGridSpanDataChange(elementIndex, data);
          }}
          addNewChild={(parentKey) => {
            let updatedChildData = { ...element.childData }; // Create a new object to avoid mutating the original
            // console.log(parentKey[parentKey]);
            if (!updatedChildData[parentKey]) {
              updatedChildData[parentKey] = []; // Initialize the array if it doesn't exist
            }
            const newChild = {}; // Create a new child object
            // Assuming childTemplate has a structure to clone from
            const childTemplate = item.childTemplate;
            if (childTemplate) {
              for (const key in childTemplate.fields) {
                newChild[key] = childTemplate.fields[key].sample || ""; // Use sample or empty string
              }
            }
            updatedChildData[parentKey].push(newChild); // Add the new child to the existing array
            console.log("added new child");
            addChildData(elementIndex, updatedChildData);
          }}
          onChildChange={(index, parentKey, key, value) => {
            let updatedChildData = { ...element.childData };
            if (updatedChildData[parentKey][index]) {
              updatedChildData[parentKey][index] = { ...updatedChildData[parentKey][index], [key]: value };
            }
            addChildData(elementIndex, updatedChildData);
          }}
        />
      );
    } else {
      return <div>No Element Selected</div>;
    }
  };
  const renderElementSidbar = (elementData) => {
    const { elementIndex } = elementData;
    const element = jobAd[elementIndex];
    if (element) {
      const section = sections.find((item) => item.key === element.elementType);
      const item = section.templates?.find((item) => item._id === element.template);
      if (item) {
        return (
          <DynamicSidebar
            deleteChild={(index, parentKey) => {
              let updatedChildData = { ...element.childData };
              if (updatedChildData[parentKey]) {
                // Create new array with the item removed
                updatedChildData[parentKey] = updatedChildData[parentKey].filter((_, idx) => idx !== index);
                addChildData(elementIndex, updatedChildData);
              }
            }}
            key={"side" + elementIndex}
            setLoaderBox={setLoaderBox}
            item={item}
            fieldData={element.data}
            childData={element.childData}
            t={t}
            onChange={(item, value) => {
              const data = { ...element.data, [item]: value };
              handleGridSpanDataChange(elementIndex, data);
            }}
            onChildChange={(index, parentKey, key, value) => {
              let updatedChildData = { ...element.childData };
              if (updatedChildData[parentKey][index]) {
                updatedChildData[parentKey][index] = { ...updatedChildData[parentKey][index], [key]: value };
              }
              addChildData(elementIndex, updatedChildData);
            }}
          />
        );
      } else {
        return <div>No Element Selected</div>;
      }
    }
  };
  useEffect(() => {
    const ProcessData = async () => {
      const response = await getData({ template: adBuildTemplate.id }, "ad-builder/element-types");
      setSections(response.data);
    };
    ProcessData();
  }, [adBuildTemplate.id]);

  const removeElement = (elementIndex) => {
    updateJobAdWork([...jobAd].filter((_, index) => index !== elementIndex));
  };

  const handleGridSpanChange = (elementIndex, newSpan, screenSize) => {
    updateJobAdWork([...jobAd].map((element, index) => (index === elementIndex ? { ...element, ...(screenSize === "Mobile" ? { mobileGridSpan: newSpan } : { gridSpan: newSpan }) } : element)));
  };
  const handleGridSpanDataChange = (elementIndex, data) => {
    updateJobAdWork([...jobAd].map((element, index) => (index === elementIndex ? { ...element, data: data } : element)));
  };
  const addChildData = (elementIndex, data) => {
    updateJobAdWork([...jobAd].map((element, index) => (index === elementIndex ? { ...element, childData: data } : element)));
  };

  const renderElement = (id, elementIndex, element) => {
    const section = sections.find((item) => item.key === element.elementType);
    const item = section?.templates?.find((item) => item._id === element.template);
    return item ? (
      <Draggable key={element.id} draggableId={element.id} index={elementIndex}>
        {(provided) => (
          <ElementBox ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} onClick={() => setSideBarSettings({ id, elementIndex })} key={elementIndex} gridSpan={screenSize === "Mobile" ? element.mobileGridSpan : element.gridSpan}>
            <div style={{ position: "relative" }}> {renderElementData(element, elementIndex)} </div>
            {!fullScreen && (
              <Actions className="controlls">
                <GridWrapper>
                  {(screenSize === "Mobile" ? item.mobileGridOptions : screenSize === "Tablet" ? item.tabletGridOptions : item.gridOptions)?.map((span) => (
                    <GridButton key={span} span={span} active={screenSize === "Mobile" ? element.mobileGridSpan === span : screenSize === "Tablet" ? element.tabletGridSpan === span : element.gridSpan === span} onClick={() => handleGridSpanChange(elementIndex, span, screenSize)}>
                      {"| ".repeat(screenSize === "Mobile" ? span / 2 : span)}
                    </GridButton>
                  ))}
                </GridWrapper>
                <Controls className="side">
                  <IconButton align="delete medium" icon="delete" ClickEvent={() => removeElement(elementIndex)}></IconButton>
                </Controls>
              </Actions>
            )}
          </ElementBox>
        )}
      </Draggable>
    ) : (
      <Draggable key={element.id} draggableId={element.id} index={elementIndex}>
        {(provided) => (
          <ElementBox ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} key={elementIndex} gridSpan={screenSize === "Mobile" ? element.mobileGridSpan : element.gridSpan}>
            <div style={{ position: "relative" }}> Not available </div>
            {!fullScreen && (
              <Actions className="controlls">
                <Controls className="side">
                  <IconButton align="delete medium" icon="delete" ClickEvent={() => removeElement(elementIndex)}></IconButton>
                </Controls>
              </Actions>
            )}
          </ElementBox>
        )}
      </Draggable>
    );
  };
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = [...jobAd];
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    updateJobAdWork(newItems);
  };
  const renderJobAd = () => {
    return (
      <div>
        {jobAd.length === 0 && <PlaceholderText>Your job ad preview will appear here.</PlaceholderText>}
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="list" mode="standard">
            {(provided) => (
              <div
                style={{
                  minHeight: "50px",
                  display: "grid",
                  gridTemplateColumns: "repeat(4, 1fr)",
                }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {jobAd.map((element, elementIndex) => renderElement(element.id, elementIndex, element))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  };

  const addContainerWithSecontopInside = (sectionKey, template) => {
    const newSection = sections.find((section) => section.key === sectionKey); // Find the section based on the key
    const templateItem = newSection.templates.find((item) => item._id === template);
    const fieldsString = templateItem?.fields ?? [];
    const fields = fieldsString.length > 0 ? JSON.parse(fieldsString) : [];
    const exist = jobAd.filter((item) => item.template === templateItem._id);
    if (exist.length >= templateItem.addLimit && templateItem.addLimit !== "Any") {
      setMessage({
        type: 1,
        content: "There is limit adding this element to the Job Ad!",
        icon: "error",
      });
      return;
    }
    const newContainer = {
      id: uuidv4(),
      elementType: sectionKey, // Assuming each section has a type
      template,
      content: templateItem.content || "", // Assuming this section may have some default content
      gridSpan: templateItem.defaultGridSpan || 4, // Set default grid span
      mobileGridSpan: templateItem.defaultMobileGrid || 4, // Set default grid span
      tabletGridSpan: templateItem.defaultTabletGrid || 4, // Set default grid span
      data: {},
      childData: fields.reduce((acc, field) => {
        acc[field.name] = field.dataset ?? [];
        return acc;
      }, {}),
    };

    updateJobAdWork([...jobAd, newContainer]); // Add the new container to jobAd state
  };

  const setSideBarSettings = (element) => {
    setCurrentSidebar("settings");
    setCurrentSidebarData(element);
  };
  return (
    <MainContainer>
      <Container className="sub">
        <Left>
          <Title>
            <IconButtonTransparent ClickEvent={() => onClose(null)} icon={"arrow-left"} />
            {item.Titel}
          </Title>
        </Left>
        <Right>
          <Button value={t("Preview")} icon="enlarge" ClickEvent={() => setFullScreen(true)}></Button>
          <Button
            isDisabled={!jobadChanged}
            icon={"discard"}
            style={{ background: "#DDDDDD", color: "#6A6A6A" }}
            ClickEvent={() => {
              getJobAd();
            }}
            value={t("Discard")}
          />
          <Button
            isDisabled={!jobadChanged}
            icon={"draft"}
            style={{ background: "#F9E375", color: "#094B96" }}
            ClickEvent={() => {
              updateJobAd();
            }}
            value={t("Draft")}
          />
          <Button
            isDisabled={!jobadChanged}
            icon={"send"}
            ClickEvent={() => {
              publishJobad();
            }}
            value={t("Publish")}
          />
        </Right>
      </Container>
      <MainContent>
        <Sidebar>
          <SideHead className="text">
            Template Used: <span>{adBuildTemplate.value}</span>
          </SideHead>

          <SideHead>
            <TabButtons
              tabs={[
                { icon: "elements", title: "Elements", key: "Elements" },
                { icon: "styles", title: "Styles", key: "Styles" },
              ]}
              selectedTab={selectionType}
              selectedChange={(val) => setSelectionType(val)}
            ></TabButtons>
          </SideHead>
          {selectionType === "Elements" ? (
            <SectionContaier>
              {currentSidebar === "settings" ? (
                <SectionContaieritem>
                  <SideHead>
                    <div onClick={() => setCurrentSidebar("elements")}>
                      <IconButtonTransparent align="autoheight" icon={"arrow-left"} />
                      Settings
                    </div>
                  </SideHead>
                  <FormSections>{renderElementSidbar(currentSidebarData)}</FormSections>
                </SectionContaieritem>
              ) : currentSidebar === "templates" ? (
                <SectionContaieritem>
                  <SideHead>
                    <div
                      onClick={() => {
                        setCurrentSidebar("elements");
                        setTemplates(null);
                      }}
                    >
                      <IconButtonTransparent align="autoheight" icon={"arrow-left"} />
                      Templates
                    </div>
                  </SideHead>
                  <Sections>
                    {templates?.map((item, index) => (
                      <Section
                        onClick={() => {
                          addContainerWithSecontopInside(item.section, item._id);
                        }}
                        key={index}
                      >
                        <span> Template {index + 1}</span>
                      </Section>
                    ))}
                  </Sections>
                </SectionContaieritem>
              ) : (
                <SectionContaieritem>
                  <Sections>
                    {sections.map((item, index) => (
                      <Section
                        onClick={() => {
                          setTemplates(item.templates);
                          //addContainerWithSecontopInside(item.key)
                          setCurrentSidebar("templates");
                        }}
                        key={index}
                      >
                        {<GetIcon icon={item.name.toLowerCase().replace(/\s+/g, "")}></GetIcon>}
                        <span> {item.name}</span>
                      </Section>
                    ))}
                  </Sections>
                </SectionContaieritem>
              )}
            </SectionContaier>
          ) : selectionType === "Styles" ? (
            <Sections key={"colors"} className="full">
              <Accordian>
                <AccordianTitle style={{ cursor: "pointer" }}>
                  Style Content{" "}
                  <IconButton
                    align="small"
                    icon={openedStyles[0] ? "up" : "down"}
                    ClickEvent={(e) => {
                      e.stopPropagation();
                      setOpenedStyles((prev) => prev.map((value, index) => (index === 0 ? !value : value)));
                    }}
                  ></IconButton>
                </AccordianTitle>
                {openedStyles[0] && <AccordianContent></AccordianContent>}
              </Accordian>
              <Accordian>
                <AccordianTitle style={{ cursor: "pointer" }}>
                  Color Theme
                  <IconButton
                    align="small"
                    icon={openedStyles[1] ? "up" : "down"}
                    ClickEvent={(e) => {
                      e.stopPropagation();
                      setOpenedStyles((prev) => prev.map((value, index) => (index === 1 ? !value : value)));
                    }}
                  ></IconButton>
                </AccordianTitle>
                {openedStyles[1] && (
                  <AccordianContent>
                    <StyledColorBoxTheme
                      key={"color-select"}
                      onChange={(cssVariables) => {
                        // Handle CSS variable updates
                        setCssVariables(cssVariables);
                      }}
                      initialColors={colors}
                      updateColors={async (colors) => {
                        await updateColors(colors);
                      }}
                    />
                  </AccordianContent>
                )}
              </Accordian>

              {/* <TextArea value={mainCss} onChange={(text) => setMainCss(text)} label="Main CSS" placeholder={"Paste you CSS content here!"}></TextArea> */}
            </Sections>
          ) : null}
        </Sidebar>
        <RightContent>
          {!fullScreen && (
            <MainCntrols view={screenSize}>
              <TabButtons
                tabs={[
                  { icon: "desktop", title: "", key: "Desktop" },
                  { icon: "tablet", title: "", key: "Tablet" },
                  { icon: "mobileview", title: "", key: "Mobile" },
                ]}
                selectedTab={screenSize}
                selectedChange={(val) => setScreenSize(val)}
              ></TabButtons>
              <div className="memory">
                <IconButton isDisabled={history.length === 0} icon="undo" ClickEvent={undo}></IconButton>
                <IconButton isDisabled={history.length === 0} icon="redo" ClickEvent={redo}></IconButton>
              </div>
            </MainCntrols>
          )}
          {/* Job Ad Preview Area */}
          <PreviewArea fullScreen={fullScreen} view={screenSize}>
            {sections.length > 0 && renderJobAd()}
          </PreviewArea>
          {/* <div>
            <pre>
              <code>{JSON.stringify({ currentSidebarData, jobAd }, null, 2)}</code>
            </pre>
          </div> */}
        </RightContent>
      </MainContent>
      {fullScreen && (
        <Floating>
          <IconButton
            ClickEvent={async () => {
              setLoaderBox(true);
              colors &&
                (await DownloadHtml(jobAd, sections, styles, colors, {
                  title: item.Titel,
                  description: item.description,
                  keywords: "",
                  location: {
                    einsatzort: portal.Locations,
                    postleitzahl: portal.PostalCode,
                    bewerben: portal.SearchOverview,
                  },
                  branding: {
                    heroImage: "",
                    backgroundImage: "",
                    primaryColor: "#F5F5F5",
                    secondaryColor: "#00528c",
                  },
                  template: "1",
                  anzeige: "2",
                }));
              setLoaderBox(false);
            }}
            icon="download"
          ></IconButton>
          <IconButton ClickEvent={() => setFullScreen(false)} icon="close"></IconButton>
        </Floating>
      )}
    </MainContainer>
  );
};

export default JobAdBuilder;
