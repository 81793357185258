import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { postData } from "../../../backend/api";
import AutoForm from "../../core/autoform/AutoForm";
import Footer from "../footer";
import { BtnForgotPassword, FormContainer, ImageBox, LoginBox, MainContainer, Switcher } from "./styles";
//using translation
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ColumnContainer, RowContainer } from "../../styles/containers/styles";
import Header from "../header";
import { clearLoginSession, fetchLogin } from "../../../store/actions/login";
import withLayout from "../layout";
import { projectSettings } from "../../project/brand/project";
import Registration from "./registration";
import { help } from "../../project/brand";
import ForgotPassword from "./forgot";
import AddNewCompany from "./request";
import { GetIcon } from "../../../icons";
import { Select } from "../../core/elements";

const Login = (props) => {
  useEffect(() => {
    document.title = projectSettings.title;
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login);
  const { t } = useTranslation();
  const [formType, setFormType] = useState("login");
  const [companyRegistration, setcompanyRegistration] = useState(null);
  const [formInput] = useState([
    {
      type: "text",
      placeholder: "email",
      name: "email",
      validation: "email",
      default: "",
      label: "email",
      add: true,
      minimum: 5,
      maximum: 40,
      required: true,
    },
    {
      type: "password",
      placeholder: "password",
      name: "password",
      add: true,
      validation: "",
      info: "At least one uppercase letter (A-Z) \n At least one lowercase letter (a-z) \n At least one digit (0-9) \n At least one special character (@, $, !, %, *, ?, &) \n Minimum length of 8 characters",
      default: "",
      label: "password",
      minimum: 0,
      maximum: 16,
      required: true,
    },
    {
      type: "html",
      content: (
        <BtnForgotPassword className="hover" onClick={() => setFormType("forgot")}>
          <GetIcon icon={"open"}></GetIcon>
          {t("ForgotPassword")}
        </BtnForgotPassword>
      ),
      name: "info",
      add: true,
    },
  ]);
  const { setLoaderBox, setMessage } = props;
  useEffect(() => {
    if (user.data?.token) {
      navigate(user.data?.menu[0]?.path ?? "dashboard");
    }
    setLoaderBox(user.isLoading);
    if (user.error !== null) {
      props.setMessage({ type: 1, content: t(user.error), proceed: "Okay", icon: "error" });
      dispatch(clearLoginSession());
    }
  }, [user, navigate, setLoaderBox, t, props, dispatch]);

  const isCreatingHandler = (value, callback) => {};
  const submitChange = async (post) => {
    dispatch(fetchLogin(post));
  };

  return (
    <MainContainer>
      <RowContainer
        className="login"
        style={{
          background: "#094B96",
        }}
      >
        <Header />
        <FormContainer className="login">
          <ColumnContainer className="loginbox">
            <ImageBox>
              <img src={help} alt="img"></img>
            </ImageBox>
            <LoginBox>
              {formType === "login" && formInput ? (
                <React.Fragment>
                  <AutoForm useCaptcha={false} key={`type-${1}`} useCheckbox={false} customClass={"embed"} description={""} formValues={{}} formMode={"single"} formType={"post"} header={t("loginHead")} formInput={formInput} submitHandler={submitChange} button={t("validate")} isOpenHandler={isCreatingHandler} css="plain embed" isOpen={true} plainForm={true}></AutoForm>
                </React.Fragment>
              ) : formInput && formType === "forgot" ? (
                <ForgotPassword setMessage={setMessage} setLoaderBox={setLoaderBox} setLogin={() => setFormType("login")} />
              ) : (
                <React.Fragment>
                  {companyRegistration === null ? (
                    <Select
                      label="companyRegistration"
                      onSelect={(data) => {
                        setcompanyRegistration(data.id);
                      }}
                      radioButton={true}
                      apiType="JSON"
                      selectApi={[
                        { id: true, value: "Yes" },
                        { id: false, value: "No" },
                      ]}
                    ></Select>
                  ) : companyRegistration ? (
                    <Registration setMessage={setMessage} setLoaderBox={setLoaderBox} setMainFormType={(page) => setFormType(page)} />
                  ) : (
                    <AddNewCompany setMessage={setMessage} setLoaderBox={setLoaderBox} setLogin={() => setFormType("login")} />
                  )}
                </React.Fragment>
              )}
            </LoginBox>
          </ColumnContainer>
          {formType === "login" ? <Switcher action="#" onClick={() => setFormType("register")} dangerouslySetInnerHTML={{ __html: t("notRegistered") }} /> : <Switcher action="#" onClick={() => setFormType("login")} dangerouslySetInnerHTML={{ __html: t("alreadyRegistered") }} />}
        </FormContainer>
        <Footer />
      </RowContainer>
    </MainContainer>
  );
};

export default withLayout(Login);
