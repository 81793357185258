import React from "react";
import Menu from "../../project/pages/menu";
import Franchise from "../../project/pages/franchise";
import Page404 from "../../project/pages/page404";
import UserType from "../../project/pages/user/userType";
import Settings from "../pages/settings";
import Login from "../../public/login";
import MyOrders from "../pages/myOrders";
import ApprovalTool from "../pages/approval";
import Tasks from "../pages/approval/tasks";
import ToDo from "../pages/toDo";
import Templates from "../pages/templates";

const RenderPage = (page, key, user, privileges) => {
  switch (page) {
    case "login":
      return <Login key={key} />;
    case "menu":
      return <Menu key={key} {...privileges} />;
    case "franchise":
      return <Franchise key={key} {...privileges} />;
    case "user-role":
      return <UserType key={key} {...privileges} />;
    case "my-orders":
      return <MyOrders key={key} {...privileges} />;
    case "tasks":
      return <Tasks key={key} {...privileges} />;
    case "approval-tool":
      return <ApprovalTool key={key} {...privileges} />;
    case "todo":
      return <ToDo key={key} {...privileges} />;
    case "templates":
      return <Templates key={key} {...privileges} />;
    case "settings":
      return <Settings key={key} {...privileges} />;
    default:
      return <Page404 key={key}></Page404>;
  }
};

export default RenderPage;
