import React, { useState, useRef, useEffect, useCallback, memo } from "react";
import styled from "styled-components";
import { Button } from "./styles";

const ThemeContainer = styled.div`
  background: white;
`;

const ColorGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(50% - 0.75rem), 1fr));
  gap: 0.5rem;

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`;

const ColorBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  font-size: 0.875rem;
  font-weight: 500;
  color: #4b5563;
  text-transform: capitalize;
`;

const ColorPreviewContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0rem;
`;

const Input = styled.input`
  width: 70px;
  flex: 1 1 0%;
  padding: 0.5rem 0.75rem;
  border: 1px solid rgb(229, 231, 235);
  border-radius: 8px 0 0 8px;
  font-size: 0.8rem;
  color: rgb(55, 65, 81);
  height: 24px;
  transition: border-color 0.2s;
  &:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
  }
`;

const ColorPicker = styled.input`
  width: 25px;
  height: 24px;
  padding: 0;
  border: 1px solid rgb(229, 231, 235);
  cursor: pointer;
  border-radius: 0 8px 8px 0;
  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  &::-webkit-color-swatch {
    border: none;
    border-radius: 0 8px 8px 0;
  }
`;

const PreviewSection = styled.div`
  margin-top: 0rem;
  background: #f9fafb;
  border-radius: 0.75rem;
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const PreviewTitle = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: #1f2937;
  border-bottom: 1px solid lightgray;
`;

const GradientSection = styled.div`
  margin-top: 0.5rem;
`;

const GradientPreview = styled.div`
  height: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  background: ${(props) => props.gradient};
`;

// Memoized ColorInput component
const ColorInput = memo(
  ({ value, onChange, section, colorKey }) => {
    const inputRef = useRef(null);
    const [localValue, setLocalValue] = useState(value);
    const cursorPositionRef = useRef(null);

    useEffect(() => {
      if (value !== localValue) {
        setLocalValue(value);
      }
    }, [value, localValue]);

    const handleTextChange = useCallback(
      (e) => {
        e.preventDefault();
        const newValue = e.target.value;
        const newCursorPosition = e.target.selectionStart;

        cursorPositionRef.current = newCursorPosition;
        setLocalValue(newValue);

        // Defer parent update to next tick to ensure local state is set
        Promise.resolve().then(() => {
          onChange(section, colorKey, newValue);
        });

        // Restore cursor in next frame
        requestAnimationFrame(() => {
          if (inputRef.current && document.activeElement === inputRef.current) {
            inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
          }
        });
      },
      [onChange, section, colorKey]
    );

    return (
      <ColorPreviewContainer>
        <Input ref={inputRef} type="text" value={localValue} onChange={handleTextChange} />
        <ColorPicker type="color" value={value} onChange={(e) => onChange(section, colorKey, e.target.value)} />
      </ColorPreviewContainer>
    );
  },
  (prevProps, nextProps) => {
    // Custom comparison for memo
    return prevProps.value === nextProps.value && prevProps.section === nextProps.section && prevProps.colorKey === nextProps.colorKey;
  }
);

// Memoized ColorBox component
const MemoizedColorBox = memo(({ colorKey, value, section, onChange }) => (
  <ColorBox>
    <Label>{colorKey} Color</Label>
    <ColorInput value={value} onChange={onChange} section={section} colorKey={colorKey} />
  </ColorBox>
));

// Memoized ColorInputGroup
const ColorInputGroup = memo(({ section, title, colors, onChange }) => (
  <>
    <PreviewTitle>{title}</PreviewTitle>
    <ColorGrid>
      {Object.entries(colors[section]).map(([key, value]) => (
        <MemoizedColorBox key={`${section}-${key}`} colorKey={key} value={value} section={section} onChange={onChange} />
      ))}
    </ColorGrid>
  </>
));
export const getColorVars = (newColors) => {
  const cssVariables = newColors
    ? `  
  :root {  
    --color-primary: ${newColors.bg.primary};  
    --color-secondary: ${newColors.bg.secondary};  
    --color-accent: ${newColors.bg.accent};  
    --color-dark: ${newColors.bg.dark};  
    --color-light: ${newColors.bg.light};  
    --text-primary: ${newColors.text.primary};  
    --text-secondary: ${newColors.text.secondary};  
    --text-accent: ${newColors.text.accent};  
    --text-dark: ${newColors.text.dark};  
    --text-light: ${newColors.text.light};  
    --gradient-primary: linear-gradient(135deg, var(--color-primary), var(--color-secondary));  
    --gradient-accent: linear-gradient(135deg, var(--color-primary) 0%, transparent 100%);  
    --overlay-light: rgba(255, 255, 255, 0.95);  
    --overlay-dark: rgba(0, 0, 0, 0.1);  
    --overlay-glass: rgba(255, 255, 255, 0.1);  
  }  
`
    : "";
  return cssVariables;
};
const StyledColorBoxTheme = ({ onChange: onChangeProps, updateColors, initialColors }) => {
  const [gradients, setGradients] = useState();
  const [colors, setColors] = useState(
    initialColors ?? {
      bg: {
        primary: "#2563eb",
        secondary: "#4f46e5",
        accent: "#f59e0b",
        dark: "#1f2937",
        light: "#f3f4f6",
      },
      text: {
        primary: "#ffffff",
        secondary: "#ffffff",
        accent: "#ffffff",
        dark: "#ffffff",
        light: "#ffffff",
      },
    }
  );

  // Memoize the color change handler
  const handleColorChange = useCallback(
    (section, key, value) => {
      setColors((prev) => {
        // Don't update if value hasn't changed
        if (prev[section][key] === value) {
          return prev;
        }

        const newColors = {
          ...prev,
          [section]: {
            ...prev[section],
            [key]: value,
          },
        };

        // Generate CSS variables
        if (onChangeProps) {
          const cssVariables = getColorVars(colors);
          onChangeProps(cssVariables);
        }

        return newColors;
      });
    },
    [onChangeProps, colors]
  );

  // Memoize the gradients to prevent unnecessary recalculations
  useEffect(() => {
    const colorsTemp = initialColors ?? {
      bg: {
        primary: "#2563eb",
        secondary: "#4f46e5",
        accent: "#f59e0b",
        dark: "#1f2937",
        light: "#f3f4f6",
      },
      text: {
        primary: "#ffffff",
        secondary: "#ffffff",
        accent: "#ffffff",
        dark: "#ffffff",
        light: "#ffffff",
      },
    };
    const gradients = {
      primary: `linear-gradient(135deg, ${colorsTemp?.bg.primary}, ${colorsTemp?.bg.secondary})`,
      accent: `linear-gradient(135deg, ${colorsTemp?.bg.primary} 0%, transparent 100%)`,
    };
    setGradients(gradients);
  }, [initialColors]);

  return (
    colors &&
    gradients && (
      <ThemeContainer>
        <PreviewSection>
          <ColorInputGroup section="bg" title="Background Colors" colors={colors} onChange={handleColorChange} />
          <ColorInputGroup section="text" title="Text Colors" colors={colors} onChange={handleColorChange} />
          <GradientSection>
            <PreviewTitle>Generated Gradients</PreviewTitle>
            <GradientPreview gradient={gradients.primary} />
            <GradientPreview gradient={gradients.accent} />
          </GradientSection>
          <Button
            onClick={() => {
              updateColors(JSON.stringify(colors));
            }}
          >
            Update Colors
          </Button>
        </PreviewSection>
      </ThemeContainer>
    )
  );
};
export default StyledColorBoxTheme;
