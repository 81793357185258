import React, { useEffect, useState } from "react";
import Layout from "../../../core/layout";
import { Container } from "../../../core/layout/styels";
import { projectSettings } from "../../brand/project";
import ListTable from "../../../core/list/list";
import JobAdTemplateBuilder from "../../../core/htmlBuilder/template";
//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const Templates = (props) => {
  useEffect(() => {
    document.title = `My Orders - ${projectSettings.title}`;
  }, []);
  const [editMode, setEditMode] = useState(null);
  const [attributes] = useState([
    {
      type: "text",
      placeholder: "Title",
      name: "Title",
      showItem: "Title",
      validation: "",
      default: "",
      label: "Title",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "textarea",
      placeholder: "Description",
      name: "Description",
      showItem: "Description",
      collection: "",
      validation: "",
      default: "",
      label: "Description",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },

    {
      type: "datetime",
      placeholder: "CreatedOn",
      name: "CreatedOn",
      showItem: "CreatedOn",
      collection: "",
      validation: "",
      default: "",
      label: "CreatedOn",
      required: true,
      view: true,
      add: false,
      update: false,
      tag: true,
      sort: true,
      defaultSort: "desc",
    },

    {
      type: "text",
      placeholder: "CreatedBy",
      name: "CreatedByUser",
      showItem: "FirstName",
      collection: "CreatedByUser",
      validation: "",
      default: "",
      label: "CreatedBy",
      required: false,
      view: true,
      add: false,
      update: false,
      tag: true,
      sort: true,
      defaultSort: "desc",
    },

    {
      type: "datetime",
      placeholder: "ModifiedOn",
      name: "ModifiedOn",
      showItem: "ModifiedOn",
      collection: "",
      validation: "",
      default: "",
      label: "ModifiedOn",
      required: true,
      view: true,
      add: false,
      update: false,
      tag: true,
      sort: true,
      defaultSort: "desc",
    },
    {
      type: "text",
      placeholder: "ModifiedBy",
      name: "ModifiedByUser",
      showItem: "FirstName",
      collection: "ModifiedByUser",
      validation: "",
      default: "",
      label: "ModifiedBy",
      required: false,
      view: true,
      add: false,
      update: false,
      tag: true,
      sort: true,
      defaultSort: "desc",
    },
  ]);
  const [actions] = useState([
    {
      id: "1",
      element: "callback",
      type: "callback",
      callback: (item, data, refreshView) => {
        // Handle request change/correction action
        setEditMode(data);
        // console.log("Request change/correction", data);
      },
      icon: "open",
      title: "Template Builder",
    },
  ]);
  return (
    <Container className="noshadow">
      {/* <RowContainer className={"data-layout"}>
        {data && data.map((item, index) => <div key={index}>{item.Titel}</div>)}
      </RowContainer> */}
      {/* Render a ListTable component */}
      <ListTable
        viewMode="table"
        // Actions to be displayed in the ListTable
        actions={actions}
        // API endpoint for fetching menu data
        api={`template`}
        // Property name for the title of each menu item
        itemTitle={{ name: "Titel", type: "text", collection: "" }}
        // Short name or label for the menu
        shortName={`Template`}
        // Privilege flag indicating whether the user can add menu items
        // formMode={`single`}
        formMode={`double`}
        //
        {...props}
        delPrivilege={true}
        // Additional attributes related to the menu
        attributes={attributes}
      ></ListTable>
      {editMode && <JobAdTemplateBuilder setMessage={props.setMessage} setLoaderBox={props.setLoaderBox} onClose={() => setEditMode(false)} template={editMode} />}
    </Container>
  );
};
// exporting the page with parent container layout..
export default Layout(Templates);
