import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AutoForm from "../../../core/autoform/AutoForm";
import { postData } from "../../../../backend/api";
import Loader from "../../../core/loader";
import { SuccessMessageContainer } from "../styles";
import { useSelector } from "react-redux";

const AddNewCompany = ({ setMessage }) => {
  const themeColors = useSelector((state) => state.themeColors);
  const { t } = useTranslation();
  const [requestComplete, setRequestComplete] = useState(false);
  const [loader, setLoader] = useState(false);

  const formInput = useMemo(
    () => [
      {
        type: "text",
        placeholder: "CompanyName",
        name: "CompanyName",
        validation: "text",
        customClass: "full",
        default: "",
        add: true,
        label: "CompanyName",
        minimum: 2,
        maximum: 30,
        required: true,
      },
      {
        type: "textarea",
        placeholder: "CompanyAddress",
        customClass: "full",
        name: "CompanyAddress",
        validation: "text",
        default: "",
        add: true,
        label: "CompanyAddress",
        minimum: 5,
        maximum: 100,
        required: true,
      },
      {
        type: "text",
        placeholder: "ContactPersonFirstName",
        name: "ContactPersonFirstName",
        validation: "text",
        default: "",
        add: true,
        label: "ContactPersonFirstName",
        minimum: 2,
        maximum: 15,
        required: true,
      },
      {
        type: "text",
        placeholder: "ContactPersonLastName",
        name: "ContactPersonLastName",
        validation: "text",
        default: "",
        add: true,
        label: "ContactPersonLastName",
        minimum: 2,
        maximum: 15,
        required: true,
      },
      {
        type: "select",
        placeholder: "ContactPersonGender",
        name: "ContactPersonGender",
        validation: "select",
        default: "",
        add: true,
        showLabel: true,
        label: "ContactPersonGender",
        apiType: "JSON",
        selectApi: [
          { id: 1, value: t("female") },
          { id: 2, value: t("male") },
          { id: 3, value: t("diverse") },
        ],
        required: true,
      },
      {
        type: "text",
        placeholder: "ContactEmail",
        name: "ContactEmail",
        validation: "email",
        default: "",
        add: true,
        label: "ContactEmail",
        minimum: 5,
        maximum: 40,
        required: true,
      },
      {
        type: "text",
        placeholder: "ContactPhone",
        name: "ContactPhone",
        validation: "phone",
        default: "",
        add: true,
        label: "ContactPhone",
        minimum: 10,
        maximum: 15,
        required: true,
      },
    ],
    [t]
  );

  const submitChange = useCallback(
    async (post) => {
      setLoader(true);
      try {
        const response = await postData(post, "user/signup-request");
        if (response.status === 200) {
          setLoader(false);
          setRequestComplete(true);
          setMessage({
            type: 1,
            content: t("SignupRequestSuccess"),
            proceed: "Okay",
            icon: "success",
          });
        } else {
          setLoader(false);
          setMessage({
            type: 1,
            content: t(response.customMessage),
            proceed: "Okay",
            icon: "error",
          });
        }
      } catch (error) {
        setLoader(false);
        setMessage({
          type: 1,
          content: t(error),
          proceed: "Okay",
          icon: "error",
        });
        console.error("Error during form submission:", error);
      }
    },
    [setMessage, t]
  );

  return (
    <>
      {requestComplete ? (
        <SuccessMessageContainer theme={themeColors}>
          <div className="success-icon">✓</div>
          <h2>{t("companySignupRequestSubmitted")}</h2>
          <p>{t("weWillVerifyAndApproveASAP")}</p>
        </SuccessMessageContainer>
      ) : (
        <AutoForm useCaptcha={false} useCheckbox={false} customClass="embed" description="" formValues={{}} formMode="double" formType="post" header={t("addNewCompany")} formInput={formInput} submitHandler={submitChange} button={t("Submit Request")} isOpenHandler={() => {}} css="plain embed" isOpen={true} plainForm={true} />
      )}
      {loader && <Loader />}
    </>
  );
};

export default AddNewCompany;
