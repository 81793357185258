import React from "react";
import styled from "styled-components";
import { GetIcon } from "../../../icons";

const CheckboxWrapper = styled.label`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* Frame 1000003991 */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 11px 20px;
  gap: 15px;

  background: #f6faff;
  border: 1px solid rgba(9, 75, 150, 0.12);
  border-radius: 10px;

  &.checked {
    background: rgba(9, 75, 150, 0.17);
    border: 1px solid #094b96;
    border-radius: 10px;
  }
`;

const CheckboxLabel = styled.span`
  font-size: 14px;
  cursor: pointer;
`;

const SelectItem = ({ label, checked, onChange, theme, className = "", t }) => {
  return (
    <CheckboxWrapper className={checked ? "checked" : ""} onClick={onChange}>
      {checked && <GetIcon icon={"checked"} />}
      <CheckboxLabel>{t(label)}</CheckboxLabel>
    </CheckboxWrapper>
  );
};

export default SelectItem;
