import React, { useState, useEffect } from "react";
import { MainContent, Sidebar, RightContent, PreviewArea, ElementBox, MainCntrols, Sections, Section, SideHead, SectionContaier, SectionContaieritem, MainContainer, MainBox, ElementTemplateContainer, ElementTemplateHeading, Fields, Field, AddField, Accordian, AccordianContent, AccordianTitle } from "./styles";
import { deleteData, getData, postData, putData } from "../../../backend/api";
import { IconButton, IconButtonTransparent, TabButtons, Button } from "../elements";
import { GetIcon } from "../../../icons";
import { useTranslation } from "react-i18next";
import { Container, Heading, Left, Right, Title } from "../layout/header/styels";
import { NoData } from "../list/styles";
import CodeEditor from "../csseditor";
import { StyledRoundNo } from "../../project/pages/approval/styles";
import AutoForm from "../autoform/AutoForm";
import { avathar } from "../../../images";
import { INPUT_TYPES, UnifiedInput } from "./UnifiedInput";
import StyledColorBoxTheme, { getColorVars } from "./themeColors";
// import DynamicTemplate from "./dynamic";

const JobAdTemplateBuilder = ({ onClose, template, setMessage, setLoaderBox }) => {
  const [screenSize, setScreenSize] = useState("Desktop");
  const [currentSectionsTemplates, setCurrentSectionsTemplates] = useState([]);
  const [selectionType, setSelectionType] = useState("Elements");
  const [currentSection, setCurrentSection] = useState(null);
  const [mainCss, setMainCss] = useState("");
  const [openedStyles, setOpenedStyles] = useState([false, false]);
  const [colors, setColors] = useState(null);
  const [cssVariables, setCssVariables] = useState(getColorVars(colors));
  const [templateData, setTemplateData] = useState([]);
  const { t } = useTranslation();
  const [sections, setSections] = useState([]);
  const [isOpen, setIsOpen] = useState(null);
  const [ratioOptions] = useState([
    { id: 5 / 2, value: "Logo", width: 5, height: 2 },
    { id: 3 / 1, value: "3:1 (Wide Logo)", width: 3, height: 1 },
    { id: 4 / 1, value: "4:1 (Extra Wide Logo)", width: 4, height: 1 },
    { id: 5 / 1, value: "5:1 (Super Wide Logo)", width: 5, height: 1 },
    { id: 6 / 1, value: "6:1 (Ultra Wide Logo)", width: 6, height: 1 },
    { id: 1, value: "1:1 (Quadratisch)", width: 1, height: 1 },
    { id: 4 / 3, value: "4:3 (Standardformat)", width: 4, height: 3 },
    { id: 16 / 9, value: "16:9 (Breitbild)", width: 16, height: 9 },
    { id: 5 / 2, value: "5:2 (Landschaftsformat)", width: 5, height: 2 },
    { id: 3 / 2, value: "3:2 (Leicht breiter als hoch)", width: 3, height: 2 },
    { id: 2 / 1, value: "2:1 (Panoramaformat)", width: 2, height: 1 },
    { id: 9 / 16, value: "9:16 (Hochformat)", width: 9, height: 16 },
    { id: 2 / 3, value: "2:3 (Portrait)", width: 2, height: 3 },
    { id: 4 / 5, value: "4:5 (Portrait)", width: 4, height: 5 },
    { id: 3 / 1, value: "3:1 (Wide Banner)", width: 3, height: 1 },
    { id: 5 / 4, value: "5:4 (Leicht quadratisch)", width: 5, height: 4 },
    { id: 21 / 9, value: "21:9 (Ultra-Widescreen)", width: 21, height: 9 },
    { id: 16 / 10, value: "16:10 (Widescreen)", width: 16, height: 10 },
  ]);
  const [jobAdLoaded, setJobAdLoaded] = useState("");
  useEffect(() => {
    const getJobAd = async () => {
      const response = await getData({ ID: template.ID }, "template");
      setTemplateData(response.data.response);
      setMainCss(response.data.TemplateData);
      setColors(response.data.TemplateData.colors);
      setCssVariables(getColorVars(response.data.TemplateData.colors));
    };
    if (!jobAdLoaded) {
      getJobAd();
    }
    setJobAdLoaded(true);
  }, [template.ID, jobAdLoaded]);
  const saveDraft = async (post) => {
    const response = await putData({ reference: mainCss.reference, ...post }, "template/data");
    if (response.status === 200) {
      setMainCss(response.data.templateData);
      // setColors(mainCss.colors);
      setMessage({
        type: 1,
        content: response.data.message,
        icon: "success",
      });
    }
  };
  const getElmentTemplates = async (element) => {
    setCurrentSection(element);
    const response = await getData({ section: element.key, reference: template.ID }, "template/sections");
    if (response.status === 200) {
      const elements = response.data.response.map((item, index) => {
        const fields = item.fields ? JSON.parse(item.fields) : [];
        const dataset = item.dataset ? JSON.parse(item.dataset) : [];
        const { exist, notExist } = getVariablesExistAndNotExist(item.htmlTemplate, fields);
        return { ...item, exist, notExist, fields, dataset, ...(index === 0 ? { opened: true } : {}) };
      });
      setCurrentSectionsTemplates(elements);
    }
  };
  useEffect(() => {
    const ProcessData = async () => {
      const response = await getData({}, "ad-builder/element-types");
      // console.log(response);
      setSections(response.data);
    };
    ProcessData();
  }, []);
  useEffect(() => {
    // Create a <style> element
    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = cssVariables + mainCss.draftStyles;
    // Append the <style> element to the <head>
    style.id = "mobile-styles";

    const modifyMediQueryToCanvas = () => {
      // Replace all instances of "media" with "canvas" in the style innerHTML
      style.innerHTML = style.innerHTML.replace(/@media/g, "@container");
    };

    modifyMediQueryToCanvas();
    document.head.appendChild(style);
    // Cleanup function to remove the style when the component unmounts
    return () => {
      document.head.removeChild(style);
    };
  }, [mainCss.draftStyles, cssVariables]);
  const renderElement = (key = 0, template) => {
    // Helper function to find field value
    const getFieldValue = (fieldName, isChild = false, childfield) => {
      if (!template.fields) return "sampleText";

      const field = isChild ? childfield.fields?.find((item) => item.name === fieldName) : template.fields.find((item) => item.name === fieldName);

      if (!field) return "sampleText";

      // For image type, return sampleImage if available
      if (field.type === "image") {
        return field.sampleImage || "sampleImage";
      }

      if (field.type === "text") {
        return field.sampleText || "sampleText";
      }

      if (field.type === "list") {
        const template = field.htmlTemplate;

        // Check if dataset exists and has items
        if (!field.dataset || !Array.isArray(field.dataset)) {
          return "No dataset available";
        }

        // Map through dataset with demo limit
        return field.dataset
          .map((dataItem) => {
            // Process template with dataset values
            return template?.replace(/{([^{}]+)}/g, (match, variable) => {
              const trimmedVar = variable.trim();
              // Use the value from dataset if available, otherwise fallback to getFieldValue
              return dataItem[trimmedVar] || getFieldValue(trimmedVar, true, field);
            });
          })
          .join("");
      }

      return "sampleText";
    };
    // Process template to replace variables
    const processTemplate = (htmlString) => {
      if (!htmlString) return "";

      return htmlString.replace(/{([^{}]+)}/g, (match, variable) => {
        const trimmedVar = variable.trim();
        const value = getFieldValue(trimmedVar);
        return value;
      });
    };

    const processedHtml = processTemplate(template.htmlTemplate);

    return <ElementBox onClick={() => {}} key={key} gridSpan={4} dangerouslySetInnerHTML={{ __html: processedHtml }} />;
  };
  const AddNewElementTemplate = async () => {
    const response = await postData({ section: currentSection.key, reference: template.ID }, "template/section");
    if (response.status === 200) {
      setCurrentSectionsTemplates((prev) => [...prev, response.data.response]);
    }
  };
  const UpdateElementTemplate = async (post) => {
    const response = await putData({ ...post }, "template/section");
    if (response.status === 200) {
      const fields = response.data.response.fields ? JSON.parse(response.data.response.fields) : [];
      const { exist, notExist } = getVariablesExistAndNotExist(response.data.response.htmlTemplate, fields);
      setCurrentSectionsTemplates((prev) => prev.map((item) => (item._id === post.id ? { ...item, ...response.data.response, opened: item.opened, status: item.status, exist, notExist, fields } : item)));
      setMessage({
        type: 1,
        content: response.data.message,
        icon: "success",
      });
    }
  };
  const DeleteElementTemplate = async (post) => {
    const response = await deleteData({ ...post }, "template/section");
    if (response.status === 200) {
      setCurrentSectionsTemplates((prev) => prev.filter((item) => item._id !== post.id));
      setMessage({
        type: 1,
        content: response.data.message,
        icon: "success",
      });
    }
  };
  function getVariablesExistAndNotExist(htmlTemplate, fields) {
    // Extract variables from htmlTemplate using regex '{variableName}'
    const variableMatches = htmlTemplate.match(/\{([^}]+)\}/g);
    const variableNames = variableMatches ? variableMatches.map((v) => v.replace(/[{}]/g, "")) : [];

    // Initialize arrays to hold variables that exist and do not exist in fields
    const exist = [];
    const notExist = [];

    // Create a Set of field names for efficient lookup
    const fieldNamesSet = new Set(fields ? fields.map((field) => field.name) : []);

    // Determine which variables exist or do not exist in fields
    variableNames.forEach((variable) => {
      if (fieldNamesSet.has(variable)) {
        exist.push(variable);
      } else {
        notExist.push(variable);
      }
    });

    return {
      exist,
      notExist,
    };
  }
  const handleAddField = async (item, index, fieldIndex) => {
    try {
      const newField = {
        name: item,
        label: item,
        content: "",
        sample: "Sample Data",
        type: "text",
      };

      const newSections = [...currentSectionsTemplates];

      if (typeof fieldIndex === "number") {
        // Initialize fields array if it doesn't exist for nested fields
        if (!newSections[index].fields[fieldIndex].fields) {
          newSections[index].fields[fieldIndex].fields = [];
        }
        newSections[index].fields[fieldIndex].fields.push(newField);

        // Update exist/notExist for nested fields
        const { exist, notExist } = getVariablesExistAndNotExist(newSections[index].fields[fieldIndex].htmlTemplate, newSections[index].fields[fieldIndex].fields);
        newSections[index].fields[fieldIndex] = {
          ...newSections[index].fields[fieldIndex],
          exist,
          notExist,
        };
      } else {
        // Initialize fields array if it doesn't exist for top level
        if (!newSections[index].fields) newSections[index].fields = [];
        newSections[index].fields.push(newField);

        // Update exist/notExist for top level
        const { exist, notExist } = getVariablesExistAndNotExist(newSections[index].htmlTemplate, newSections[index].fields);
        newSections[index] = { ...newSections[index], exist, notExist };
      }

      // Update template and state
      await UpdateElementTemplate({
        id: newSections[index]._id,
        fields: JSON.stringify(newSections[index].fields),
      });
      setCurrentSectionsTemplates(newSections);
    } catch (error) {
      console.error("Error adding field:", error);
    }
  };
  const handleDeleteField = async (templateIndex, fieldIndex, fieldChildIndex) => {
    try {
      const newTemplates = [...currentSectionsTemplates];
      const templateToUpdate = newTemplates[templateIndex];
      let updatedFields;

      if (typeof fieldChildIndex === "number") {
        // Handle nested field deletion
        updatedFields = templateToUpdate.fields[fieldIndex].fields.filter((_, index) => index !== fieldChildIndex);

        const { exist, notExist } = getVariablesExistAndNotExist(templateToUpdate.fields[fieldIndex].htmlTemplate, updatedFields);

        templateToUpdate.fields[fieldIndex] = {
          ...templateToUpdate.fields[fieldIndex],
          fields: updatedFields,
          exist,
          notExist,
        };
      } else {
        // Handle top-level field deletion
        updatedFields = templateToUpdate.fields.filter((_, index) => index !== fieldIndex);

        const { exist, notExist } = getVariablesExistAndNotExist(templateToUpdate.htmlTemplate, updatedFields);

        newTemplates[templateIndex] = {
          ...templateToUpdate,
          fields: updatedFields,
          exist,
          notExist,
        };
      }

      // Update backend and state
      await UpdateElementTemplate({
        id: templateToUpdate._id,
        fields: JSON.stringify(updatedFields),
      });

      setCurrentSectionsTemplates(newTemplates);
    } catch (error) {
      console.error("Error deleting field:", error);
    }
  };
  const handleUpdateFieldDataset = async (templateIndex, fieldIndex, post, update = true, rowIndex) => {
    try {
      const newTemplates = [...currentSectionsTemplates];
      // Get the template to update
      const templateToUpdate = newTemplates[templateIndex];
      if (templateToUpdate) {
        templateToUpdate.fields[fieldIndex].dataset[rowIndex] = { ...templateToUpdate.fields[fieldIndex].dataset[rowIndex], ...post };

        // Update the backend
        await UpdateElementTemplate({
          id: templateToUpdate._id,
          fields: JSON.stringify(templateToUpdate.fields), // Send all fields
        });
      }
      setIsOpen(null);
    } catch {}
  };
  const handleUpdateField = async (templateIndex, fieldIndex, post, update = true, fieldChildIndex) => {
    try {
      // Create a deep copy of the current templates to avoid mutation
      const newTemplates = [...currentSectionsTemplates];
      // Get the template to update
      const templateToUpdate = newTemplates[templateIndex];

      //-----------------
      if (typeof fieldChildIndex === "number") {
        if (!templateToUpdate || !templateToUpdate.fields) {
          throw new Error("Template or fields not found");
        }
        if (!templateToUpdate.fields[fieldIndex] || !templateToUpdate.fields[fieldIndex].fields) {
          throw new Error("Template or fields not found");
        }
        templateToUpdate.fields[fieldIndex].fields[fieldChildIndex] = {
          ...templateToUpdate.fields[fieldIndex].fields[fieldChildIndex],
          ...post,
        };
      } else {
        // Process dataset if post.demo exists
        if (post.demo !== undefined) {
          const currentDataset = templateToUpdate.fields[fieldIndex].dataset ?? [];
          const newDemoCount = parseInt(post.demo);

          // Initialize dataset if it doesn't exist
          if (!templateToUpdate.fields[fieldIndex].dataset) {
            templateToUpdate.fields[fieldIndex].dataset = [];
          }

          if (currentDataset.length < newDemoCount) {
            const startIndex = currentDataset?.length || 0; // Safely get current length
            // Generate new rows
            const newRows = Array.from({ length: newDemoCount - currentDataset.length }, (_, index) => ({
              _id: startIndex + index + 1,
            }));
            // Append new rows to dataset
            templateToUpdate.fields[fieldIndex].dataset = [...currentDataset, ...newRows];

            // Append new rows to dataset
            templateToUpdate.fields[fieldIndex].dataset = [...currentDataset, ...newRows];
          } else if (currentDataset.length > newDemoCount) {
            // Need to remove excess data
            templateToUpdate.fields[fieldIndex].dataset = currentDataset.slice(0, newDemoCount);
          }
        }
        if (!templateToUpdate || !templateToUpdate.fields) {
          throw new Error("Template or fields not found");
        }

        // Update the specific field with new data
        templateToUpdate.fields[fieldIndex] = {
          ...templateToUpdate.fields[fieldIndex],
          ...post,
        };
      }
      if (update) {
        // Update the backend
        await UpdateElementTemplate({
          id: templateToUpdate._id,
          fields: JSON.stringify(templateToUpdate.fields), // Send all fields
        });
      }
      // Update the state with new templates
      setCurrentSectionsTemplates(newTemplates); // Optionally, show success message
      setIsOpen(null);
      // toast.success('Field updated successfully');
    } catch (error) {
      console.error("Error updating field:", error);
      throw error;
    }
  };
  return (
    <MainContainer>
      <Container className="sub">
        <Left>
          <Title>
            <IconButtonTransparent ClickEvent={() => onClose(null)} icon={"arrow-left"} />
            {templateData?.Title}
          </Title>
        </Left>
        <Right>
          <TabButtons
            tabs={[
              { icon: "desktop", title: "", key: "Desktop" },
              { icon: "tablet", title: "", key: "Tablet" },
              { icon: "mobileview", title: "", key: "Mobile" },
            ]}
            selectedTab={screenSize}
            selectedChange={(val) => setScreenSize(val)}
          ></TabButtons>
          <Button
            icon={"send"}
            ClickEvent={() => {
              // getJobAd();
            }}
            value={t("Publish")}
          />
        </Right>
      </Container>
      <MainContent>
        <Sidebar>
          <SectionContaier>
            <SectionContaieritem>
              <SideHead>
                <TabButtons
                  tabs={[
                    { icon: "elements", title: "Elements", key: "Elements" },
                    { icon: "styles", title: "Styles", key: "Styles" },
                  ]}
                  selectedTab={selectionType}
                  selectedChange={(val) => setSelectionType(val)}
                ></TabButtons>
              </SideHead>
              {selectionType === "Elements" ? (
                <Sections>
                  {sections.map((item, index) => (
                    <Section className={currentSection?.key === item.key ? "true" : ""} onClick={() => getElmentTemplates(item)} key={index}>
                      {<GetIcon icon={item.name.toLowerCase().replace(/\s+/g, "")}></GetIcon>}
                      <span> {item.name}</span>
                    </Section>
                  ))}
                </Sections>
              ) : (
                <Sections key={"colors"} className="full">
                  <Accordian>
                    <AccordianTitle style={{ cursor: "pointer" }}>
                      Style Content{" "}
                      <IconButton
                        align="small"
                        icon={openedStyles[0] ? "up" : "down"}
                        ClickEvent={(e) => {
                          e.stopPropagation();
                          setOpenedStyles((prev) => prev.map((value, index) => (index === 0 ? !value : value)));
                        }}
                      ></IconButton>
                    </AccordianTitle>
                    {openedStyles[0] && (
                      <AccordianContent>
                        <CodeEditor
                          value={mainCss.draftStyles}
                          onChange={async (value) => {
                            // setMainCss();
                            await saveDraft({ draftStyles: value });
                          }}
                          label="Main CSS"
                          placeholder={"Paste you CSS content here!"}
                        />
                      </AccordianContent>
                    )}
                  </Accordian>
                  <Accordian>
                    <AccordianTitle style={{ cursor: "pointer" }}>
                      Color Theme
                      <IconButton
                        align="small"
                        icon={openedStyles[1] ? "up" : "down"}
                        ClickEvent={(e) => {
                          e.stopPropagation();
                          setOpenedStyles((prev) => prev.map((value, index) => (index === 1 ? !value : value)));
                        }}
                      ></IconButton>
                    </AccordianTitle>
                    {openedStyles[1] && (
                      <AccordianContent>
                        <StyledColorBoxTheme
                          key={"color-select"}
                          onChange={(cssVariables) => {
                            // Handle CSS variable updates
                            setCssVariables(cssVariables);
                          }}
                          initialColors={colors}
                          updateColors={async (colors) => {
                            await saveDraft({ colors });
                          }}
                        />
                      </AccordianContent>
                    )}
                  </Accordian>

                  {/* <TextArea value={mainCss} onChange={(text) => setMainCss(text)} label="Main CSS" placeholder={"Paste you CSS content here!"}></TextArea> */}
                </Sections>
              )}
            </SectionContaieritem>
          </SectionContaier>
        </Sidebar>
        <RightContent>
          <ElementTemplateHeading>
            <MainCntrols>
              <div className="head"> {currentSection?.name}</div>
            </MainCntrols>
          </ElementTemplateHeading>
          {currentSectionsTemplates?.length > 0 ? (
            currentSectionsTemplates.map((templateItem, index) => {
              return (
                <ElementTemplateContainer key={templateItem.id || index}>
                  {/* <MainCntrols view={screenSize}></MainCntrols> */}
                  <MainCntrols view={screenSize}>
                    <div className="head">
                      <StyledRoundNo>{index + 1}</StyledRoundNo>
                    </div>
                    <TabButtons
                      tabs={[
                        { icon: "demo", title: "Demo", key: index + "demo" },
                        { icon: "html", title: "HTML", key: index + "HTML" },
                        { icon: "fields", title: "Fields", key: index + "Fields" },
                        { icon: "settings", title: "Settings", key: index + "Settings" },
                        { icon: templateItem.opened ? "up" : "down", title: "", key: index + "action" },
                      ]}
                      selectedTab={templateItem.status || (index === 0 ? index + "demo" : "")}
                      selectedChange={(val) =>
                        setCurrentSectionsTemplates((prev) => {
                          return prev.map((item, i) => (i === index ? { ...item, status: val === index + "action" ? item.status : val, opened: val === index + "action" ? !(item.opened ?? false) : true } : item));
                        })
                      }
                    />
                  </MainCntrols>
                  {templateItem.opened ? (
                    templateItem.status === index + "demo" || !templateItem.status ? (
                      <PreviewArea view={screenSize}>{renderElement(templateItem.id, templateItem)}</PreviewArea>
                    ) : templateItem.status === index + "HTML" ? (
                      <MainBox>
                        <CodeEditor
                          language="html"
                          value={templateItem.htmlTemplate}
                          onChange={(value) => {
                            // setMainCss();
                            // await saveDraft({ draftStyles: value });
                            UpdateElementTemplate({ id: templateItem._id, htmlTemplate: value });
                          }}
                          label="HTML Template"
                          placeholder={"Paste you HTML content here!"}
                        />
                      </MainBox>
                    ) : templateItem.status === index + "Fields" ? (
                      <MainBox>
                        <Fields>
                          <Field>
                            <table>
                              <thead>
                                <tr>
                                  <th>Variable</th>
                                  <th>Type</th>
                                  <th>Label</th>
                                  <th>Content</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {templateItem.fields?.length > 0 ? (
                                  templateItem.fields?.map((item, fieldIndex) => (
                                    <React.Fragment>
                                      <tr key={item.name || fieldIndex}>
                                        <td>{`{${item.name}}`}</td>
                                        <td>{item.type || "N/A"}</td>
                                        <td>{item.label || "N/A"}</td>
                                        <td>{item.type === "list" ? `Demo: ${item.demo}, Limit: ${item.limit}` : item.type === "image" ? <img src={item.sampleImage} alt={item.type} /> : item.sampleText}</td>
                                        <td>
                                          <span>
                                            {item.type === "list" && (
                                              <IconButton
                                                align="small"
                                                icon={item.open ? "up" : "down"}
                                                label="Template"
                                                ClickEvent={() => {
                                                  handleUpdateField(index, fieldIndex, { fields: item.fields ?? [], open: item.open ? false : true }, false);
                                                }}
                                              />
                                            )}
                                            <IconButton
                                              align="small"
                                              icon="edit"
                                              label="Edit"
                                              ClickEvent={() => {
                                                setIsOpen({
                                                  submitHandler: (post) => {
                                                    handleUpdateField(index, fieldIndex, post);
                                                  },
                                                  submit: "Update Now",
                                                  api: "mock/update",
                                                  header: `Configure field for {${item.name}}`,
                                                  description: "",
                                                  attributes: [
                                                    {
                                                      type: "text",
                                                      placeholder: "Label",
                                                      name: "label",
                                                      showItem: "",
                                                      validation: "",
                                                      default: item.label ?? "", //add exisitng data if needed
                                                      label: "Label",
                                                      required: true,
                                                      view: true,
                                                      add: true,
                                                      update: true,
                                                    },
                                                    {
                                                      type: "select",
                                                      placeholder: "Input Type",
                                                      listView: true,
                                                      radioButton: true,
                                                      name: "type",
                                                      validation: "",
                                                      default: item.type,
                                                      label: "Input Type",
                                                      required: true,
                                                      view: true,
                                                      customClass: "list",
                                                      add: true,
                                                      update: true,
                                                      apiType: "JSON",
                                                      search: false,
                                                      selectApi: [
                                                        { value: "Text", id: "text" },
                                                        { value: "Image", id: "image" },
                                                        { value: "Textarea", id: "textarea" },
                                                        { value: "List", id: "list" },
                                                      ],
                                                    },
                                                    {
                                                      type: "textarea",
                                                      placeholder: "Sample",
                                                      name: "sampleText",
                                                      showItem: "",
                                                      validation: "",
                                                      default: item.sampleText ?? "", //add exisitng data if needed
                                                      label: "Sample",
                                                      arrayOut: true,
                                                      required: true,
                                                      condition: {
                                                        item: "type",
                                                        if: "textarea",
                                                        then: "enabled",
                                                        else: "disabled",
                                                      },
                                                      view: true,
                                                      add: true,
                                                      update: true,
                                                    },
                                                    {
                                                      type: "textarea",
                                                      placeholder: "Sample",
                                                      name: "sampleText",
                                                      showItem: "",
                                                      validation: "",
                                                      default: item.sampleText ?? "", //add exisitng data if needed
                                                      label: "Sample",
                                                      arrayOut: true,
                                                      required: true,
                                                      condition: {
                                                        item: "type",
                                                        if: "text",
                                                        then: "enabled",
                                                        else: "disabled",
                                                      },
                                                      view: true,
                                                      add: true,
                                                      update: true,
                                                    },
                                                    {
                                                      // Select input with options loaded from JSON data
                                                      type: "select",
                                                      placeholder: "Aspect Ratio",
                                                      name: "ratio",
                                                      condition: {
                                                        item: "type",
                                                        if: "image",
                                                        then: "enabled",
                                                        else: "disabled",
                                                      },
                                                      validation: "",
                                                      default: item.ratio,
                                                      label: "Aspect Ratio",
                                                      required: true,
                                                      onChange: (name, updateValue) => {
                                                        const { ratioArray } = updateValue;
                                                        updateValue["width"] = ratioArray.width;
                                                        updateValue["height"] = ratioArray.height;
                                                        return updateValue;
                                                      },
                                                      arrayOut: true,
                                                      view: true,
                                                      add: true,
                                                      update: true,
                                                      filter: false,
                                                      apiType: "JSON", // Specifies the data source type as JSON
                                                      selectApi: ratioOptions,
                                                    },
                                                    {
                                                      type: "number",
                                                      placeholder: "Demo Count",
                                                      name: "demo",
                                                      showItem: "",
                                                      validation: "",
                                                      default: item.demo ?? 1, //add exisitng data if needed
                                                      label: "Demo Count",
                                                      required: true,
                                                      condition: {
                                                        item: "type",
                                                        if: "list",
                                                        then: "enabled",
                                                        else: "disabled",
                                                      },
                                                      view: true,
                                                      add: true,
                                                      update: true,
                                                    },
                                                    {
                                                      type: "number",
                                                      placeholder: "Max Limit",
                                                      name: "limit",
                                                      showItem: "",
                                                      validation: "",
                                                      default: item.limit ?? 1, //add exisitng data if needed
                                                      label: "Max Limit",
                                                      required: true,
                                                      condition: {
                                                        item: "type",
                                                        if: "list",
                                                        then: "enabled",
                                                        else: "disabled",
                                                      },
                                                      view: true,
                                                      add: true,
                                                      update: true,
                                                    },
                                                    {
                                                      type: "hidden",
                                                      placeholder: "height",
                                                      name: "Height",
                                                      showItem: "",
                                                      validation: "",
                                                      default: item.height ?? 1, //add exisitng data if needed
                                                      label: "Height",
                                                      required: true,
                                                      condition: {
                                                        item: "type",
                                                        if: "image",
                                                        then: "enabled",
                                                        else: "disabled",
                                                      },
                                                      view: true,
                                                      add: true,
                                                      update: true,
                                                    },
                                                    {
                                                      type: "hidden",
                                                      placeholder: "Width",
                                                      name: "width",
                                                      showItem: "",
                                                      validation: "",
                                                      default: item.width ?? 1, //add exisitng data if needed
                                                      label: "Width",
                                                      required: true,
                                                      condition: {
                                                        item: "type",
                                                        if: "image",
                                                        then: "enabled",
                                                        else: "disabled",
                                                      },
                                                      view: true,
                                                      add: true,
                                                      update: true,
                                                    },
                                                    {
                                                      type: "image",
                                                      placeholder: "Sample",
                                                      name: "sampleImage",
                                                      showItem: "",
                                                      validation: "",
                                                      height: item.height ?? 1,
                                                      width: item.width ?? 1,
                                                      default: item.sampleImage ?? "", //add exisitng data if needed
                                                      references: { Category: "template", template: template.ID, Company: template.CompanyId },
                                                      label: "sample",
                                                      required: true,
                                                      condition: {
                                                        item: "type",
                                                        if: "image",
                                                        then: "enabled",
                                                        else: "disabled",
                                                      },
                                                      view: true,
                                                      add: true,
                                                      update: true,
                                                    },
                                                  ],
                                                });
                                              }}
                                            />
                                            <IconButton
                                              align="small delete"
                                              icon="delete"
                                              ClickEvent={() => {
                                                handleDeleteField(index, fieldIndex);
                                              }}
                                            />
                                          </span>
                                        </td>
                                      </tr>
                                      {item.type === "list" && item.open && (
                                        <tr>
                                          <td colSpan={5}>
                                            <div>
                                              <CodeEditor
                                                language="html"
                                                value={item.htmlTemplate}
                                                onChange={(value) => {
                                                  // setMainCss();
                                                  // await saveDraft({ draftStyles: value });
                                                  // UpdateElementTemplate({ id: templateItem._id, htmlTemplate: value });
                                                  const { exist, notExist } = getVariablesExistAndNotExist(value, item.fields ?? []);
                                                  handleUpdateField(index, fieldIndex, { ...item, htmlTemplate: value, exist, notExist });
                                                }}
                                                label={"List Item " + item.label}
                                                placeholder={"Paste you HTML content here!"}
                                              ></CodeEditor>
                                              {item.notExist?.map((noitem) => (
                                                <AddField className="bottom" key={noitem}>
                                                  <span>
                                                    Add new child field '{noitem}' for '{item.name}'
                                                  </span>
                                                  <IconButton icon="add" ClickEvent={() => handleAddField(noitem, index, fieldIndex)} />
                                                </AddField>
                                              ))}
                                              <Title className="sub">Fields for `{item.label}`</Title>
                                              <Field>
                                                <table>
                                                  <thead>
                                                    <tr>
                                                      <th>Variable</th>
                                                      <th>Type</th>
                                                      <th>Label</th>
                                                      <th>Sample</th>
                                                      <th>Actions</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {item.fields?.length > 0 ? (
                                                      item.fields?.map((lsitField, fieldChildIndex) => (
                                                        <React.Fragment>
                                                          <tr key={lsitField.name || fieldChildIndex}>
                                                            <td>{`{${lsitField.name}}`}</td>
                                                            <td>{lsitField.type || "N/A"}</td>
                                                            <td>{lsitField.label || "N/A"}</td>
                                                            <td>
                                                              {lsitField.type === "image" ? (
                                                                <span>
                                                                  <img src={lsitField.sampleImage} alt={lsitField.type} />
                                                                </span>
                                                              ) : (
                                                                lsitField.sampleText
                                                              )}
                                                            </td>
                                                            <td>
                                                              <span>
                                                                <IconButton
                                                                  align="small"
                                                                  icon="edit"
                                                                  label="Edit"
                                                                  ClickEvent={() => {
                                                                    setIsOpen({
                                                                      submitHandler: (post) => {
                                                                        handleUpdateField(index, fieldIndex, post, true, fieldChildIndex);
                                                                      },
                                                                      submit: "Update Now",
                                                                      api: "mock/update",
                                                                      header: `Configure field for {${lsitField.name}}`,
                                                                      description: "",
                                                                      attributes: [
                                                                        {
                                                                          type: "text",
                                                                          placeholder: "Label",
                                                                          name: "label",
                                                                          showItem: "",
                                                                          validation: "",
                                                                          default: lsitField.label ?? "", //add exisitng data if needed
                                                                          label: "Label",
                                                                          required: true,
                                                                          view: true,
                                                                          add: true,
                                                                          update: true,
                                                                        },
                                                                        {
                                                                          type: "select",
                                                                          placeholder: "Input Type",
                                                                          listView: true,
                                                                          radioButton: true,
                                                                          name: "type",
                                                                          validation: "",
                                                                          default: lsitField.type,
                                                                          label: "Input Type",
                                                                          required: true,
                                                                          view: true,
                                                                          customClass: "list",
                                                                          add: true,
                                                                          update: true,
                                                                          apiType: "JSON",
                                                                          search: false,
                                                                          selectApi: [
                                                                            { value: "Text", id: "text" },
                                                                            { value: "Image", id: "image" },
                                                                            { value: "Textarea", id: "textarea" },
                                                                            { value: "List", id: "list" },
                                                                          ],
                                                                        },
                                                                        {
                                                                          type: "textarea",
                                                                          placeholder: "Sample",
                                                                          name: "sampleText",
                                                                          showItem: "",
                                                                          validation: "",
                                                                          default: lsitField.sampleText ?? "", //add exisitng data if needed
                                                                          label: "Sample",
                                                                          arrayOut: true,
                                                                          required: true,
                                                                          condition: {
                                                                            item: "type",
                                                                            if: "text",
                                                                            then: "enabled",
                                                                            else: "disabled",
                                                                          },
                                                                          view: true,
                                                                          add: true,
                                                                          update: true,
                                                                        },
                                                                        {
                                                                          type: "textarea",
                                                                          placeholder: "Sample",
                                                                          name: "sampleText",
                                                                          showItem: "",
                                                                          validation: "",
                                                                          default: lsitField.sampleText ?? "", //add exisitng data if needed
                                                                          label: "Sample",
                                                                          arrayOut: true,
                                                                          required: true,
                                                                          condition: {
                                                                            item: "type",
                                                                            if: "textarea",
                                                                            then: "enabled",
                                                                            else: "disabled",
                                                                          },
                                                                          view: true,
                                                                          add: true,
                                                                          update: true,
                                                                        },
                                                                        {
                                                                          // Select input with options loaded from JSON data
                                                                          type: "select",
                                                                          placeholder: "Aspect Ratio",
                                                                          name: "ratio",
                                                                          condition: {
                                                                            item: "type",
                                                                            if: "image",
                                                                            then: "enabled",
                                                                            else: "disabled",
                                                                          },
                                                                          validation: "",
                                                                          default: lsitField.ratio,
                                                                          label: "Aspect Ratio",
                                                                          required: true,
                                                                          onChange: (name, updateValue) => {
                                                                            const { ratioArray } = updateValue;
                                                                            updateValue["width"] = ratioArray.width;
                                                                            updateValue["height"] = ratioArray.height;
                                                                            return updateValue;
                                                                          },
                                                                          arrayOut: true,
                                                                          view: true,
                                                                          add: true,
                                                                          update: true,
                                                                          filter: false,
                                                                          apiType: "JSON", // Specifies the data source type as JSON
                                                                          selectApi: ratioOptions,
                                                                        },
                                                                        {
                                                                          type: "hidden",
                                                                          placeholder: "height",
                                                                          name: "Height",
                                                                          showItem: "",
                                                                          validation: "",
                                                                          default: lsitField.height ?? 1, //add exisitng data if needed
                                                                          label: "Height",
                                                                          required: true,
                                                                          condition: {
                                                                            item: "type",
                                                                            if: "image",
                                                                            then: "enabled",
                                                                            else: "disabled",
                                                                          },
                                                                          view: true,
                                                                          add: true,
                                                                          update: true,
                                                                        },
                                                                        {
                                                                          type: "hidden",
                                                                          placeholder: "Width",
                                                                          name: "width",
                                                                          showItem: "",
                                                                          validation: "",
                                                                          default: lsitField.width ?? 1, //add exisitng data if needed
                                                                          label: "Width",
                                                                          required: true,
                                                                          condition: {
                                                                            item: "type",
                                                                            if: "image",
                                                                            then: "enabled",
                                                                            else: "disabled",
                                                                          },
                                                                          view: true,
                                                                          add: true,
                                                                          update: true,
                                                                        },
                                                                        {
                                                                          type: "image",
                                                                          placeholder: "Sample",
                                                                          name: "sampleImage",
                                                                          showItem: "",
                                                                          validation: "",
                                                                          height: lsitField.height ?? 1,
                                                                          width: lsitField.width ?? 1,
                                                                          default: lsitField.sampleImage ?? "", //add exisitng data if needed
                                                                          references: { Category: "template", template: template.ID, Company: template.CompanyId },
                                                                          label: "sample",
                                                                          required: true,
                                                                          condition: {
                                                                            item: "type",
                                                                            if: "image",
                                                                            then: "enabled",
                                                                            else: "disabled",
                                                                          },
                                                                          view: true,
                                                                          add: true,
                                                                          update: true,
                                                                        },
                                                                      ],
                                                                    });
                                                                  }}
                                                                />
                                                                <IconButton
                                                                  align="small delete"
                                                                  icon="delete"
                                                                  ClickEvent={() => {
                                                                    handleDeleteField(index, fieldIndex, fieldChildIndex);
                                                                  }}
                                                                />
                                                              </span>
                                                            </td>
                                                          </tr>
                                                        </React.Fragment>
                                                      ))
                                                    ) : (
                                                      <tr>
                                                        <td colSpan={5}>No fields found!</td>
                                                      </tr>
                                                    )}
                                                  </tbody>
                                                </table>
                                              </Field>

                                              <Title className="sub">
                                                Preset Dataset for {item.name}
                                                {/* <pre>
                                                  <code> {JSON.stringify(item.dataset, null, 2)}</code>
                                                </pre> */}
                                                <IconButton align="small" label="Fill content with AI!" icon="reload" />
                                              </Title>
                                              <Field>
                                                <table>
                                                  <thead>
                                                    <tr>
                                                      {item.fields?.map((listField) => (
                                                        <th>{listField.label}</th>
                                                      ))}
                                                      <th>Actions</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {item.dataset?.map((data, rowIndex) => (
                                                      <tr key={rowIndex}>
                                                        {item.fields?.map((field) => (
                                                          <td>{field.type === "image" ? <img src={data?.[field.name] || avathar} alt={field.type} /> : data?.[field.name] || "--"}</td>
                                                        ))}
                                                        <td>
                                                          <span>
                                                            <IconButton
                                                              align="small"
                                                              icon="edit"
                                                              label="Edit"
                                                              ClickEvent={() => {
                                                                setIsOpen({
                                                                  submitHandler: (post) => {
                                                                    // handleUpdateField(index, fieldIndex, post, true, fieldChildIndex);
                                                                    handleUpdateFieldDataset(index, fieldIndex, post, true, rowIndex);
                                                                  },
                                                                  submit: "Update Now",
                                                                  api: "mock/update",
                                                                  header: `Edit: ${item.label} (${rowIndex + 1})`,
                                                                  description: "",
                                                                  attributes: item.fields?.map((field) =>
                                                                    field.type === "image"
                                                                      ? {
                                                                          type: "image",
                                                                          placeholder: field.label,
                                                                          name: field.name,
                                                                          showItem: "",
                                                                          validation: "",
                                                                          height: field.height ?? 1,
                                                                          width: field.width ?? 1,
                                                                          value: data?.[field.name] ?? "",
                                                                          default: data?.[field.name] ?? "", //add exisitng data if needed
                                                                          references: { Category: "template", template: template.ID, Company: template.CompanyId },
                                                                          label: field.label,
                                                                          required: true,
                                                                          view: true,
                                                                          add: true,
                                                                          update: true,
                                                                        }
                                                                      : {
                                                                          type: "text",
                                                                          placeholder: field.label,
                                                                          name: field.name,
                                                                          showItem: "",
                                                                          validation: "",
                                                                          default: data?.[field.name] ?? "", //add exisitng data if needed
                                                                          label: field.label,
                                                                          required: true,
                                                                          view: true,
                                                                          add: true,
                                                                          update: true,
                                                                        }
                                                                  ),
                                                                });
                                                              }}
                                                            />
                                                          </span>
                                                        </td>
                                                      </tr>
                                                    ))}
                                                  </tbody>
                                                </table>
                                              </Field>
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </React.Fragment>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={5}>No fields found!</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </Field>
                          {templateItem.notExist?.map((item) => (
                            <AddField key={item}>
                              <span>Add new field for '{item}'</span>
                              <IconButton icon="add" ClickEvent={() => handleAddField(item, index)} />
                            </AddField>
                          ))}
                        </Fields>
                      </MainBox>
                    ) : templateItem.status === index + "Settings" ? (
                      <MainBox>
                        <Fields>
                          <Field>
                            <table>
                              <thead>
                                <tr>
                                  <th colSpan={2}>
                                    <Heading>
                                      <GetIcon icon={"settings"}></GetIcon>
                                      <span>Settings</span>
                                    </Heading>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {[
                                  {
                                    label: "Section Type",
                                    type: INPUT_TYPES.SINGLE_SELECT,
                                    props: {
                                      options: ["Container", "Section"],
                                      selected: templateItem.sectionType,
                                      onChange: (val) => UpdateElementTemplate({ id: templateItem._id, sectionType: val }),
                                    },
                                  },

                                  {
                                    label: "Add Limit",
                                    type: INPUT_TYPES.SINGLE_SELECT,
                                    props: {
                                      options: ["1", "2", "3", "4", "Any"],
                                      selected: templateItem.addLimit.toString(),
                                      onChange: (val) => UpdateElementTemplate({ id: templateItem._id, addLimit: val }),
                                    },
                                  },
                                  {
                                    label: "Default Order",
                                    type: INPUT_TYPES.NUMBER,
                                    props: {
                                      min: 1,
                                      value: templateItem.defaultOrder || 1,
                                      onChange: (val) => UpdateElementTemplate({ id: templateItem._id, defaultOrder: val }),
                                    },
                                  },
                                  {
                                    label: "Set as Default",
                                    type: INPUT_TYPES.BOOLEAN,
                                    props: {
                                      value: templateItem.isDefault,
                                      onChange: (val) => UpdateElementTemplate({ id: templateItem._id, isDefault: val }),
                                    },
                                  },
                                  {
                                    label: "Enable Template",
                                    type: INPUT_TYPES.BOOLEAN,
                                    props: {
                                      value: templateItem.status,
                                      onChange: (val) => UpdateElementTemplate({ id: templateItem._id, status: val }),
                                    },
                                  },
                                  {
                                    type: INPUT_TYPES.HEADING,
                                    label: "Desktop Grid Options",
                                    icon: "desktop",
                                  },
                                  {
                                    label: "Grid Options",
                                    type: INPUT_TYPES.MULTI_SELECT,
                                    props: {
                                      options: [1, 2, 3, 4],
                                      selected: templateItem.gridOptions,
                                      onChange: (val) => UpdateElementTemplate({ id: templateItem._id, gridOptions: val }),
                                      renderLabel: (span) => "| ".repeat(span),
                                    },
                                  },
                                  {
                                    label: "Choose Default Grid",
                                    type: INPUT_TYPES.SINGLE_SELECT,
                                    props: {
                                      options: templateItem.gridOptions || [],
                                      selected: templateItem.defaultGrid,
                                      onChange: (val) => UpdateElementTemplate({ id: templateItem._id, defaultGrid: val }),
                                      renderLabel: (span) => "| ".repeat(span),
                                    },
                                  },
                                  {
                                    type: INPUT_TYPES.HEADING,
                                    label: "Tablet Grid Options",
                                    icon: "tablet",
                                  },
                                  {
                                    label: "Applicable Grid Options",
                                    type: INPUT_TYPES.MULTI_SELECT,
                                    props: {
                                      options: [2, 3, 4],
                                      selected: templateItem.tabletGridOptions,
                                      onChange: (val) => UpdateElementTemplate({ id: templateItem._id, tabletGridOptions: val }),
                                      renderLabel: (span) => "| ".repeat(span),
                                    },
                                  },
                                  {
                                    label: "Choose Default Grid",
                                    type: INPUT_TYPES.SINGLE_SELECT,
                                    props: {
                                      options: templateItem.tabletGridOptions || [],
                                      selected: templateItem.defaultTabletGrid,
                                      onChange: (val) => UpdateElementTemplate({ id: templateItem._id, defaultTabletGrid: val }),
                                      renderLabel: (span) => "| ".repeat(span),
                                    },
                                  },
                                  {
                                    type: INPUT_TYPES.HEADING,
                                    label: "Mbile Grid Options",
                                    icon: "mobileview",
                                  },
                                  {
                                    label: "Applicable Grid Options",
                                    type: INPUT_TYPES.MULTI_SELECT,
                                    props: {
                                      options: [2, 4],
                                      selected: templateItem.mobileGridOptions,
                                      onChange: (val) => UpdateElementTemplate({ id: templateItem._id, mobileGridOptions: val }),
                                      renderLabel: (span) => "| ".repeat(span),
                                    },
                                  },
                                  {
                                    label: "Choose Default Grid",
                                    type: INPUT_TYPES.SINGLE_SELECT,
                                    props: {
                                      options: templateItem.mobileGridOptions || [],
                                      selected: templateItem.defaultMobileGrid,
                                      onChange: (val) => UpdateElementTemplate({ id: templateItem._id, defaultMobileGrid: val }),
                                      renderLabel: (span) => "| ".repeat(span),
                                    },
                                  },
                                ].map((setting, index) => (
                                  <tr key={index} className={setting.type}>
                                    <td>
                                      {setting.type === INPUT_TYPES.HEADING ? (
                                        <Heading>
                                          {setting.icon && <GetIcon icon={setting.icon}></GetIcon>}
                                          <span> {setting.label}</span>
                                        </Heading>
                                      ) : (
                                        <span>{setting.label}</span>
                                      )}
                                    </td>
                                    <td>
                                      <UnifiedInput type={setting.type} {...setting.props} />
                                    </td>
                                  </tr>
                                ))}
                                <tr>
                                  <td colSpan={2} style={{ justifyItems: "right" }}>
                                    <Button
                                      ClickEvent={async () => {
                                        setMessage({
                                          type: 2,
                                          content: `Are you sure to delete this Template?`,
                                          proceed: "Delete",
                                          onProceed: async () => {
                                            DeleteElementTemplate({ id: templateItem._id });
                                          },
                                        });
                                      }}
                                      align="small delete red "
                                      value="Delete"
                                    ></Button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Field>
                        </Fields>
                      </MainBox>
                    ) : null
                  ) : null}
                </ElementTemplateContainer>
              );
            })
          ) : (
            <NoData>No {currentSection?.name} template added!</NoData>
          )}
          {currentSection && (
            <MainCntrols view={screenSize}>
              <IconButton icon="add" label="Add New Template" ClickEvent={AddNewElementTemplate}></IconButton>
            </MainCntrols>
          )}
          {/* <MainCntrols view={screenSize}>
            <div style={{ width: "80%" }}>
              <pre>
                <code>{JSON.stringify(currentSectionsTemplates, null, 2)}</code>
              </pre>
            </div>
          </MainCntrols> */}
        </RightContent>
        {isOpen && (
          <AutoForm
            useCaptcha={isOpen.useCaptcha}
            useCheckbox={false}
            customClass={isOpen.customClass ?? ""}
            description={isOpen.description}
            formValues={{}}
            formMode={isOpen.customClass ?? "double"}
            key={isOpen.header}
            formType={"post"}
            header={isOpen.header}
            formInput={isOpen.attributes}
            submitHandler={isOpen.submitHandler}
            button={isOpen.submit}
            isOpenHandler={(value) => {
              setIsOpen(null);
            }}
            isOpen={true}
            plainForm={false}
          ></AutoForm>
        )}
      </MainContent>
    </MainContainer>
  );
};

export default JobAdTemplateBuilder;
