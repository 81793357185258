import JSZip from "jszip";
import { saveAs } from "file-saver";
import { getColorVars } from "../htmlBuilder/themeColors";

export const DownloadHtml = async (
  jobAd,
  sections,
  styles,
  colors,
  meta = {
    title: "",
    description: "",
    keywords: "",
    location: {
      einsatzort: "",
      postleitzahl: "",
      bewerben: "",
    },
    branding: {
      heroImage: "",
      backgroundImage: "",
      primaryColor: "#F5F5F5",
      secondaryColor: "#00528c",
    },
    template: "1",
    anzeige: "2",
  },
  fontFamily = "https://fonts.googleapis.com/css2?family=Dosis:wght@300;400;500;600;700&display=swap"
) => {
  try {
    const zip = new JSZip();
    const imageCache = new Map();

    const downloadImage = async (url) => {
      try {
        // Validate URL
        if (!url || typeof url !== "string") {
          console.error("Invalid image URL:", url);
          return "";
        }

        // Check if image is already downloaded
        if (imageCache.has(url)) {
          console.log("Image already cached:", url);
          return imageCache.get(url);
        }

        // Extract filename from URL
        let filename = url.split("/").pop().split("?")[0] || "image.jpg";

        // Add timestamp to ensure unique filenames
        const timestamp = Date.now();
        const extension = filename.split(".").pop();
        const baseName = filename.substring(0, filename.lastIndexOf("."));
        filename = `${baseName}_${timestamp}.${extension}`;

        // Fetch the image
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Get the blob
        const imageBlob = await response.blob();

        // Add to zip file
        zip.file(`${filename}`, imageBlob);

        // Store in cache
        imageCache.set(url, filename);

        // Return the filename for HTML reference
        return filename;
      } catch (error) {
        console.error("Error downloading image:", error);
        return "";
      }
    };

    const replacePlaceholders = async (template, data, fieldData, childData) => {
      let htmlWithInputs = template;

      const matches = template.match(/\{(.*?)\}/g) || [];
      for (const match of matches) {
        const key = match.replace(/[{}]/g, "");
        const field = data?.find((field) => field.name === key);

        if (field) {
          let replacement = match;

          if (field.type === "list") {
            const childTemplate = field;
            if (childTemplate) {
              let childrenHtml = "";
              const dataset = field.dataset ?? [];
              const childDataItem = childData?.[key] ?? [];
              const childFields = childTemplate.fields;

              for (let i = 0; i < childDataItem.length; i++) {
                let childHtml = childTemplate.htmlTemplate;
                const childMatches = childHtml.match(/\{(.*?)\}/g) || [];

                for (const childMatch of childMatches) {
                  const childKey = childMatch.replace(/[{}]/g, "");
                  const childField = childFields?.find((field) => field.name === childKey);

                  if (childField) {
                    let childReplacement = childMatch;

                    if (childField.type === "parent") {
                      const parentField = data?.find((field) => field.name === childField.name);
                      if (parentField) {
                        if (fieldData?.[childField.name]) {
                          childReplacement = fieldData[childField.name];
                        } else {
                          childReplacement = parentField.type === "image" ? `${await downloadImage(parentField.sampleImage)}` : parentField.sampleText;
                        }
                      }
                    } else {
                      if (childField.type === "image") {
                        const imageUrl = childDataItem?.[i]?.[childKey] || dataset?.[i]?.[childKey] || childField.sampleImage;
                        childReplacement = `${await downloadImage(imageUrl)}`;
                      } else {
                        childReplacement = childDataItem?.[i]?.[childKey] || dataset?.[i]?.[childKey] || childField.sampleText;
                      }
                    }

                    childHtml = childHtml.replace(childMatch, childReplacement);
                  }
                }
                childrenHtml += childHtml;
              }
              replacement = childrenHtml;
            }
          } else {
            if (field.type === "image") {
              const imageUrl = fieldData?.[key] ? fieldData[key] : field.sampleImage;
              replacement = `${await downloadImage(imageUrl)}`;
            } else {
              replacement = fieldData?.[key] ? fieldData[key] : field.sampleText;
            }
          }

          htmlWithInputs = htmlWithInputs.replace(match, replacement);
        }
      }

      return htmlWithInputs;
    };

    // Download branding images first
    let heroImagePath = "";
    let backgroundImagePath = "";
    if (meta.branding.heroImage) {
      heroImagePath = await downloadImage(meta.branding.heroImage);
    }
    if (meta.branding.backgroundImage) {
      backgroundImagePath = await downloadImage(meta.branding.backgroundImage);
    }

    const header =
      `<!DOCTYPE html><html lang="en"><head>` +
      `<meta charset="UTF-8">` +
      `<meta name="viewport" content="width=device-width,initial-scale=1">` +
      `<meta name="description" content="${meta.description}">` +
      `<meta name="keywords" content="${meta.keywords}">` +
      `<title>${meta.title}</title>` +
      `<!--\n##Einsatzort: ${meta.location.einsatzort}\n##Postleitzahl: ${meta.location.postleitzahl}\n##Bewerben: ${meta.location.bewerben}\n-->` +
      `<meta name="bmo-stst-liquid-marker-template" content="${meta.template}">` +
      `<meta name="bmo-stst-liquid-marker-anzeige" content="${meta.anzeige}">` +
      `<!-- LD // HEADERIMAGE plain -->` +
      `<meta name="bmo-stst-brandingImageHero" content="${heroImagePath}" data-bmo-einpacken="content">` +
      `<meta name="bmo-stst-brandingImageBackground" content="${backgroundImagePath}" data-bmo-einpacken="content">` +
      `<meta name="bmo-stst-brandingPrimaryColor" content="${meta.branding.primaryColor}">` +
      `<meta name="bmo-stst-brandingSecondaryColor" content="${meta.branding.secondaryColor}">` +
      `<link rel="preconnect" href="https://fonts.googleapis.com">` +
      `<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>` +
      `<link href="${fontFamily}" rel="stylesheet">` +
      `<style>*{font-family:"Dosis";}${getColorVars(colors)} ${
        styles || ""
      }[class*=grid-]{display:grid;gap:20px}.grid-1{grid-template-columns:1fr}.grid-2{grid-template-columns:repeat(2,1fr)}.grid-3{grid-template-columns:repeat(3,1fr)}.grid-4{grid-template-columns:repeat(4,1fr)}@media screen and (max-width:1024px){.tab-1{grid-template-columns:1fr}.tab-2{grid-template-columns:repeat(2,1fr)}.tab-3{grid-template-columns:repeat(3,1fr)}.tab-4{grid-template-columns:repeat(4,1fr)}}@media screen and (max-width:768px){.mobile-1{grid-template-columns:1fr}.mobile-2{grid-template-columns:repeat(2,1fr)}.mobile-3{grid-template-columns:repeat(3,1fr)}.mobile-4{grid-template-columns:repeat(4,1fr)}}</style></head><body>`;

    const footer = `</body></html>`;

    // Generate the main content
    const contentPromises = jobAd.map(async (element) => {
      const section = sections.find((item) => item.key === element.elementType);
      const template = section?.templates?.find((item) => item._id === element.template);

      if (!template) {
        return `<!--Template not found for ${element.elementType}-->`;
      }

      try {
        const gridClass = element.gridColumns || "1";
        const tabClass = element.tabletColumns || gridClass;
        const mobileClass = element.mobileColumns || tabClass;
        const fields = JSON.parse(template.fields?.length > 0 ? template.fields : "[]");
        const fieldData = element.data;
        const childData = element.childData;
        const newHtml = await replacePlaceholders(template.htmlTemplate, fields, fieldData, childData);

        return `<div class="section grid-${gridClass} tab-${tabClass} mobile-${mobileClass}" data-element-type="${element.elementType}">${newHtml}</div>`;
      } catch (error) {
        console.error(`Error processing template for element ${element.elementType}:`, error);
        return `<!--Error processing template for ${element.elementType}-->`;
      }
    });

    const content = (await Promise.all(contentPromises)).join("");

    // Combine all parts and minify
    const fullHtml = header + content + footer;

    // Add HTML to zip
    zip.file("index.html", fullHtml);

    // Generate zip file
    const zipBlob = await zip.generateAsync({
      type: "blob",
      compression: "DEFLATE",
      compressionOptions: {
        level: 9,
      },
    });

    // Save the zip file using file-saver
    saveAs(zipBlob, "website.zip");

    return {
      html: fullHtml,
      imageMap: Object.fromEntries(imageCache),
    };
  } catch (error) {
    console.error("Error in DownloadHtml:", error);
    throw error;
  }
};
