import { getData } from "../../../backend/api";

let lastFetchTime = 0; // Variable to track the last fetch time

const fetchCounts = (bypass = false) => {
  return (dispatch) => {
    const currentTime = Date.now(); // Get the current time in milliseconds

    // Check if 20 seconds have passed since the last fetch
    if (currentTime - lastFetchTime < 20000 && !bypass) {
      return; // Exit if the fetch is called too soon
    }

    lastFetchTime = currentTime; // Update the last fetch time

    getData({}, "dashboard/counts").then((response) => {
      if (response.status === 200) {
        dispatch({
          type: "FETCH_COUNT_SUCCESS",
          payload: response.data,
        });
      } else {
      }
    });
  };
};

export { fetchCounts };
